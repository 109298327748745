import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { postService } from "./PostService";

const InsideResearchPapaerOverviews = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { title} = useParams();
  const navigate = useNavigate();

  const createSlug = (postTitle) => {
    return postTitle
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)+/g, '');
  };


  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data = await postService.getPostBySlug(title, 'research');
        console.log("Received data:", data);
        setPost(data);
        
        const correctSlug = createSlug(data.title);
        if (title !== correctSlug) {
          navigate(`/research/${correctSlug}`, { replace: true });
        }
        
        setLoading(false);
      } catch (err) {
        console.error("Error fetching post:", err);
        setError(err.response?.data?.error || "Failed to load post details");
        setLoading(false);
      }
    };

    fetchPost();
  }, [title, navigate]);

  if (loading) {
    return (
      <>
        <Navbar />
        <div className="flex flex-col items-center px-4 sm:px-6 md:px-8 py-12 md:py-20 bg-gradient-to-br from-[#18034f] via-black to-[#18034f] text-white min-h-screen">
          <div className="text-center">Loading...</div>
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Navbar />
        <div className="flex flex-col items-center px-4 sm:px-6 md:px-8 py-12 md:py-20 bg-gradient-to-br from-[#18034f] via-black to-[#18034f] text-white min-h-screen">
          <div className="text-center">{error}</div>
        </div>
        <Footer />
      </>
    );
  }

  if (!post) {
    return <div className="text-center text-white">Post not found</div>;
  }

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center px-4 sm:px-6 md:px-8 py-12 md:py-20 bg-gradient-to-br from-[#18034f] via-black to-[#18034f] text-white min-h-screen">
        <div className="flex flex-col items-start w-full max-w-4xl 2xl:max-w-[110rem] pt-4 md:pt-10">
          <div className="w-full">
            <h1 className="text-2xl sm:text-3xl md:text-4xl 2xl:text-6xl font-bold mb-4 md:mb-8 2xl:pt-20">
              {post.title}
            </h1>
            {/* <div className="w-full">
              <p className="text-base sm:text-lg 2xl:text-4xl leading-relaxed mb-4 md:mb-8 2xl:mt-12">
                <span className="font-bold">Description:</span> {post.description}
              </p>
            </div> */}
            {/* <ul className="flex flex-wrap gap-2 mb-4 md:mb-8 2xl:mt-12">
              {post.components &&
                post.components.map((component, index) =>
                  component.name.split(',').map((item, subIndex) => (
                    <li
                      className="bg-purple-900/70 px-2 sm:px-3 py-1 text-[0.8rem] sm:text-[0.9rem] 2xl:text-3xl uppercase tracking-wider text-white rounded-full"
                      key={`${index}-${subIndex}`}
                    >
                      {item.trim()}
                    </li>
                  ))
                )}
            </ul> */}
          </div>
          {/* {post.image && (
            <div className="w-full mb-4 md:mb-8 2xl:mt-12">
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-auto object-cover rounded-lg max-h-[250px] sm:max-h-[350px] md:max-h-[450px] 2xl:max-h-[800px]"
              />
            </div>
          )} */}
        </div>

        <div className="w-full max-w-4xl 2xl:max-w-[120rem]">
          {/* <h2 className="text-2xl sm:text-3xl md:text-4xl 2xl:text-5xl font-bold mb-3 md:mb-4 2xl:mb-6 2xl:mt-12">
            Content:
          </h2> */}
          <div
            dangerouslySetInnerHTML={{ __html: post.content }}
            style={post.contentStyles}
            className="content-preview prose prose-invert max-w-none"
          />
        </div>
        {/* <p className="text-gray-300 text-xs sm:text-sm mt-6 md:mt-8 2xl:text-3xl">
          Created on: {new Date(post.created_at).toLocaleDateString()}
        </p> */}
      </div>
      <Footer />
    </>
  );
};

export default InsideResearchPapaerOverviews;
