import React, { useState, useEffect } from "react";
// import Navbar from "../Navbar";
import Footer from "../Footer";

const SinglePage = () => {
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await fetch('/assets/singlepage.json');
        if (!response.ok) {
          throw new Error('Failed to fetch page data');
        }
        const data = await response.json();
        console.log("Received data:", data);
        setPageData(data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching page data:", err);
        setError("Failed to load page content");
        setLoading(false);
      }
    };

    fetchPageData();
  }, []);

  if (loading) {
    return (
      <>
        {/* <Navbar /> */}
        <div className="flex flex-col items-center px-4 sm:px-6 md:px-8 py-12 md:py-20 bg-white text-white min-h-screen">
          <div className="text-center">Loading...</div>
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return (
      <>
        {/* <Navbar /> */}
        <div className="flex flex-col items-center px-4 sm:px-6 md:px-8 py-12 md:py-20 bg-white text-white min-h-screen">
          <div className="text-center">{error}</div>
        </div>
        <Footer />
      </>
    );
  }

  if (!pageData) {
    return (
      <>
        {/* <Navbar /> */}
        <div className="flex flex-col items-center px-4 sm:px-6 md:px-8 py-12 md:py-20 bg-white text-white min-h-screen">
          <div className="text-center">Page not found</div>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      {/* <Navbar /> */}
      <div className="flex flex-col items-center px-4 sm:px-6 md:px-8 py-12 md:py-20 bg-white text-white min-h-screen">
        {/* <div className="flex flex-col items-start w-full max-w-4xl 2xl:max-w-[110rem] pt-4 md:pt-10">
          <div className="w-full">
            <h1 className="text-2xl sm:text-3xl md:text-4xl 2xl:text-6xl font-bold mb-4 md:mb-8 2xl:pt-20">
              {pageData.title}
            </h1>
            <div className="w-full">
              <p className="text-base sm:text-lg 2xl:text-4xl leading-relaxed mb-4 md:mb-8 2xl:mt-12">
                <span className="font-bold">Description:</span> {pageData.description}
              </p>
            </div>
          </div>
          {pageData.image && (
            <div className="w-full mb-4 md:mb-8 2xl:mt-12">
              <img
                src={pageData.image}
                alt={pageData.title}
                className="w-full h-auto object-cover rounded-lg max-h-[250px] sm:max-h-[350px] md:max-h-[450px] 2xl:max-h-[800px]"
              />
            </div>
          )}
        </div>

        <div className="w-full max-w-4xl 2xl:max-w-[120rem]">
          <div
            dangerouslySetInnerHTML={{ __html: pageData.content }}
            className="content-preview prose prose-invert max-w-none"
          />
        </div> */}
      </div>
      <Footer />
    </>
  );
};

export default SinglePage;