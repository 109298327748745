import React, { useState, useRef, useEffect, useContext } from "react";
import { Card, CardBody, Form, Input, Label, Button, Container, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import ShowResearchDetails from "./ShowResearchDetails";
import { postService } from "./PostService";
import './ContentPreview.css';
import { AuthContext } from "../../App.js";
import { useLocation } from 'react-router-dom';

const AddPost = () => {
    const editorRef = useRef(null);
    const fileInputRef = useRef(null);
    const location = useLocation();
    
    // States
    const { isAuthenticated ,isSuperUser} = useContext(AuthContext);
    const [document, setDocument] = useState(null);
    const [user, setUser] = useState(undefined);
    const [showPostDetails, setShowPostDetails] = useState(false);
    const [image, setImage] = useState(null);
    const [uploadedImageName, setUploadedImageName] = useState("");
    const [tag, setTag] = useState("");
    
    const [post, setPost] = useState({
        title: "",
        description: "",
        content: "",
        categoryId: "",
        components: [],
        post_type: "research"
    });
    const [loading, setLoading] = useState(false); 
    const [modalMessage, setModalMessage] = useState(""); 
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const superUserStorage = localStorage.getItem('superuser') || null;

    
    useEffect(() => {
        
        if(!isSuperUser){
            window.location.href = '/';
        }
    
    }, [isSuperUser]);

    
    const fieldChanged = (event) => {
        const { name, value } = event.target;
        setPost(prev => ({ ...prev, [name]: value }));
    };

    
    const handleEditorChange = (content, editor) => {
        setPost(prev => ({ ...prev, content }));
    };

    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate file type
            if (!file.type.startsWith('image/')) {
                toast.error("Please select an image file");
                return;
            }
            // Validate file size (e.g., 5MB limit)
            if (file.size > 50 * 1024 * 1024) {
                toast.error("File size should be less than 50MB");
                return;
            }
            setImage(file);
            setUploadedImageName(file.name);
        }
    };

    // Handle adding tags/components
    const addTag = () => {
        if (tag.trim() === "") {
            toast.error("Tag cannot be empty!");
            return;
        }
        if (post.components.includes(tag.trim())) {
            toast.error("Tag already exists!");
            return;
        }
        setPost(prev => ({
            ...prev,
            components: [...prev.components, tag.trim()]
        }));
        setTag("");
    };

    // Reset form function
    const resetForm = () => {
        setPost({
            title: "",
            description: "",
            content: "",
            categoryId: "",
            components: [],
            post_type: "research"
        });
        setImage(null);
        setUploadedImageName("");
        if (editorRef.current) {
            editorRef.current.setContent('');
        }
        setShowPostDetails(false);
    };

    const createPost = async (event) => {
        event.preventDefault();
        console.log('Starting post creation...');
    
        // Field validation
        if (!post.title.trim()) {
            toast.error("Post title is required!");
            return;
        }
    
        if (!post.description.trim()) {
            toast.error("Post description is required!");
            return;
        }
    
        if (!post.content.trim()) {
            toast.error("Post content is required!");
            return;
        }
    
        try {
            setLoading(true);
            // Create and populate FormData
            const formData = new FormData();
            
            // Add basic fields
            formData.append('title', post.title.trim());
            formData.append('description', post.description.trim());
            formData.append('content', post.content.trim());
            formData.append('post_type', post.post_type);
    
            // Add components if they exist
            if (post.components && post.components.length > 0) {
                // Convert array to string for backend processing
                const componentString = post.components.join(',');
                formData.append('component_names', componentString);
            }
    
            // Add image if it exists
            if (image) {
                formData.append('image', image);
            }
    
            // Log form data for debugging
            console.log("Sending form data:");
            for (let [key, value] of formData.entries()) {
                console.log(`${key}:`, value);
            }
    
            // Make API call
            const response = await postService.createPost(formData);
            console.log("Post creation response:", response);
    
            // Handle successful response
            if (response) {
                setDocument(response);
                toast.success("Post created successfully!");
                setShowPostDetails(true);
    
                // Reset form
                setPost({
                    title: "",
                    description: "",
                    content: "",
                    categoryId: "",
                    components: [],
                });
                setImage(null);
                setUploadedImageName("");
                if (editorRef.current) {
                    editorRef.current.setContent('');
                }
                setModalMessage("RESEARCH POSTED SUCCESSFULLY");
                setIsModalOpen(true);
                resetForm();
            }
    
        } catch (error) {
            console.error("Post creation error:", error);
            setModalMessage("POST FAILED");
            setIsModalOpen(true);
            
            // Detailed error handling
            let errorMessage = "Failed to create post";
            
            if (error.response?.data) {
                const errorData = error.response.data;
                
                
                if (typeof errorData === 'object') {
                    
                    errorMessage = Object.entries(errorData)
                        .map(([key, value]) => {
                            // Handle array of errors
                            if (Array.isArray(value)) {
                                return `${key}: ${value.join(', ')}`;
                            }
                            return `${key}: ${value}`;
                        })
                        .join('; ');
                } else if (typeof errorData === 'string') {
                    
                    errorMessage = errorData;
                }
            }
    
            
            toast.error(errorMessage);
        }finally {
            setLoading(false); 
        }
    };

    const contentStyles = {
        padding: "15px",
        fontSize: "16px",
        lineHeight: "1.6",
    };

    return (
        <>
            
            {loading && (
                <div className="loading-overlay">
                    <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
           {isSuperUser &&( <div className="wrapper pt-5 bg-gradient-to-br from-[#18034f] via-black to-[#18034f] h-screen">
                <Card className="shadow-sm border-0 pt-5 bg-gradient-to-br from-[#18034f] via-black to-[#18034f] ">
                    <CardBody className="text-white pt-5">
                        <h3>Research Post</h3>
                        <Form onSubmit={createPost}>
                            {/* Title Input */}
                            <div className="my-3">
                                <Label for="title">Post Title: </Label>
                                <Input
                                    type="text"
                                    id="title"
                                    placeholder="Enter here"
                                    className="rounded-3 w-1/2"
                                    name="title"
                                    value={post.title}
                                    onChange={fieldChanged}
                                />
                            </div>

                            {/* Post Type Dropdown */}
                            <div className="my-3">
                                <Label for="post_type">Type Of Post: </Label>
                                <Input
                                    type="select"
                                    id="post_type"
                                    name="post_type"
                                    className="rounded-3 w-1/2"
                                    value={post.post_type}
                                    onChange={fieldChanged}
                                >
                                    <option value="research">Research</option>
                                    <option value="contract">Contract</option>
                                </Input>
                            </div>

                            {/* Description Input */}
                            <div className="my-3">
                                <Label for="description">Description: </Label>
                                <Input
                                    type="text"
                                    id="description"
                                    placeholder="Enter description"
                                    className="rounded-3 w-1/2"
                                    name="description"
                                    value={post.description}
                                    onChange={fieldChanged}
                                />
                            </div>

                            {/* Components Section */}
                            <div className="my-3">
                                <Label>Components: </Label>
                                <div className="d-flex align-items-center">
                                    <Input
                                        type="text"
                                        placeholder="Add a component"
                                        className="me-2 rounded-3"
                                        value={tag}
                                        onChange={(e) => setTag(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                addTag();
                                            }
                                        }}
                                    />
                                    <Button color="primary" onClick={addTag}>
                                        Add
                                    </Button>
                                </div>
                                <div className="mt-2">
                                    <h6>List of Components:</h6>
                                    {post.components.length > 0 ? (
                                        post.components.map((component, index) => (
                                            <span
                                                key={index}
                                                className="badge bg-primary text-black me-2"
                                            >
                                                {component}
                                            </span>
                                        ))
                                    ) : (
                                        <p>No components added yet.</p>
                                    )}
                                </div>
                            </div>

                            {/* Image Upload Section */}
                            <div className="mt-3">
                                <Label for="image">Select Post Banner Image: </Label>
                                <div className="d-flex align-items-center">
                                    <Input
                                        type="file"
                                        className="d-none"
                                        innerRef={fileInputRef}
                                        onChange={handleFileChange}
                                        accept="image/*"
                                    />
                                    <Button
                                        color="secondary"
                                        onClick={() => fileInputRef.current.click()}
                                    >
                                        Choose File
                                    </Button>
                                </div>
                                {uploadedImageName && (
                                    <p className="mt-2 text-muted">
                                        Uploaded Image: {uploadedImageName}
                                    </p>
                                )}
                            </div>

                            {/* Rich Text Editor */}
                            <div className="my-3 ">
                                <Label for="content">Post Content</Label>
                                <Editor
                                    apiKey='jg8do6mehwi28bpfq1tb3m3o2i4md3zftkrwud5xc34yp1zy'
                                    onInit={(evt, editor) => (editorRef.current = editor)}
                                    value={post.content}
                                    init={{
                                        plugins: [
                                            
                                            'anchor', 'advlist', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                                            
                                            'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown',
                                            
                                            'importword', 'exportword', 'exportpdf'
                                        ],
                                        toolbar: 'anchor | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | checklist numlist bullist | emoticons charmap | removeformat',
                                        anchor_top: false,
                                        anchor_bottom: false, 
                                        paste_remove_empty_anchors: false,
                                        content_style: `
                                            ul, ol {
                                                margin-left: 20px;
                                                padding-left: 20px;
                                            }
                                            li {
                                                list-style-type: disc;
                                                margin-bottom: 5px;
                                            }
                                        `,
                                        tinycomments_mode: 'embedded',
                                        tinycomments_author: 'Author name',
                                        mergetags_list: [
                                        { value: 'First.Name', title: 'First Name' },
                                        { value: 'Email', title: 'Email' },
                                        ],
                                        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
                                    }}
                                    onEditorChange={handleEditorChange}
                                />
                            </div>

                            {/* Action Buttons */}
                            <Container className="text-center">
                                <Button type="submit" className="rounded-0" color="primary">
                                    Create Post
                                </Button>
                                <Button
                                    className="rounded-0 ms-2"
                                    color="danger"
                                    onClick={resetForm}
                                    type="button"
                                >
                                    Reset Content
                                </Button>
                            </Container>
                        </Form>
                    </CardBody>
                </Card>
            </div>)}

            
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} centered>
                <ModalHeader toggle={() => setIsModalOpen(false)}>Notification</ModalHeader>
                <ModalBody className="text-center">{modalMessage}</ModalBody>
            </Modal>

            
            {showPostDetails && (
                <ShowResearchDetails 
                    post={post}
                    image={image}
                    uploadedImageName={uploadedImageName}
                    contentStyles={contentStyles}
                />
            )}
        </>
    );
};

export default AddPost;