import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import { postService } from "./PostService";
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";

function ContractOverviewItem({ overview, onClick }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.33 1"],
  });
  const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.8, 1]);
  const opacityProgress = useTransform(scrollYProgress, [0, 1], [0.6, 1]);

  return (
    <motion.div
      ref={ref}
      style={{
        scale: scaleProgress,
        opacity: opacityProgress,
        transition: "transform 0.5s ease, opacity 0.5s ease",
      }}
      className="card-gradient w-full max-w-[600px] border border-purple-500/20 rounded-lg overflow-hidden p-6 hover:bg-purple-900/30 transition text-white cursor-pointer relative"
    >
      <div className="flex flex-col h-full justify-between">
      <div
          className={`flex flex-col h-full ${
            overview.image ? '' : 'justify-center'
          }`}
      >
      {overview.image && (
          <div className="relative w-full h-1/3 mb-4 rounded-lg overflow-hidden">
            <img
              src={overview.image}
              alt="Overview"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        )}
        <div>
          <h3 className="text-2xl 2xl:text-4xl font-semibold mb-4 text-center">{overview.title}</h3>
          <p className="leading-relaxed text-gray-200 mb-4 text-center 2xl:text-2xl">
            {overview.description}
          </p>
          {/* <ul className="flex flex-wrap justify-center gap-2 2xl:text-2xl">
            {overview.components &&
              overview.components.map((component, index) =>
                component.name.split(",").map((item, subIndex) => (
                  <li
                    className="bg-purple-900/70 px-3 py-1 text-[0.9rem] 2xl:text-xl uppercase tracking-wider text-white rounded-full"
                    key={`${index}-${subIndex}`}
                  >
                    {item.trim()}
                  </li>
                ))
              )}
          </ul> */}
        </div>
        </div>
        <div className="date-container">
          {/* <p className="text-gray-300 2xl:text-2xl text-sm mb-4">
            Post date: {new Date(overview.created_at).toLocaleDateString()}
          </p> */}
          <button
            onClick={() => onClick(overview)}
            className="bg-gradient-to-r from-purple-900 to-purple-700 text-white 2xl:text-2xl font-semibold py-3 px-4 rounded w-full hover:from-purple-800 hover:to-purple-600 transition-all duration-300 focus:ring-2 focus:ring-purple-500 focus:outline-none"
          >
            Read More
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default function ContractPapaerOverviews() {
  const [overviews, setOverviews] = useState([]);
  const [filteredOverviews, setFilteredOverviews] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
        try {
            const data = await postService.getPosts('contract');
            setOverviews(data);
            setFilteredOverviews(data);
        } catch (error) {
        console.error("Error fetching contract overviews:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let items = overviews.filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (sortOrder) {
      items = items.slice().sort((a, b) => {
        return sortOrder === "asc"
          ? new Date(a.created_at) - new Date(b.created_at)
          : new Date(b.created_at) - new Date(a.created_at);
      });
    }

    setFilteredOverviews(items);
  }, [searchQuery, sortOrder, overviews]);

  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)+/g, "");
  };

  const openOverviewDetails = (overview) => {
    const titleSlug = createSlug(overview.title);
    navigate(`/contract/${titleSlug}`);
  };

  const clearSearch = () => setSearchQuery("");

  return (
    <div className="page-gradient flex flex-col items-center py-20 text-white min-h-screen">
      <div className="text-center mb-8 mt-10">
        <h1 className="text-5xl font-bold">Contracts</h1>
      </div>

      <div className="card-gradient p-6 rounded-lg w-full max-w-3xl mb-10 mx-4">
        <div className="flex justify-between items-center">
          <label className="block text-lg 2xl:text-3xl 2xl:pb-2 mb-2">
            Search
          </label>
          <button
            onClick={clearSearch}
            className="text-gray-300 2xl:text-2xl hover:text-white transition-colors"
          >
            Clear
          </button>
        </div>
        <input
          type="text"
          placeholder="Search all articles"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-3 2xl:text-2xl rounded-lg bg-gray-800 text-white border border-purple-500/20 focus:border-purple-500 transition-all duration-300 outline-none"
        />
        <div className="mt-4 flex items-center justify-between">
          <button
            onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
            className="flex items-center text-gray-300 hover:text-white font-semibold transition-colors 2xl:text-2xl"
          >
            {sortOrder === "asc" ? (
              <FaSortAmountDownAlt className="mr-2" />
            ) : (
              <FaSortAmountUpAlt className="mr-2" />
            )}
            <span>Sort by Date</span>
          </button>
        </div>
      </div>

      <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 px-5">
        {filteredOverviews.map((overview, index) => (
          <ContractOverviewItem
            key={index}
            overview={overview}
            onClick={openOverviewDetails}
          />
        ))}
      </div>
    </div>
  );
}
