import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from '../App';
import bitoodaIcon from "../components/assets/bitoodaIcon.png";

function Navbar() {
  const { isAuthenticated, handleLogout, isSuperUser } = useContext(AuthContext);
  const [sticky, setSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [consultingTitles, setConsultingTitles] = useState([]);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => setSticky(window.scrollY > 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    fetch("/assets/consulting.json")
      .then((response) => response.json())
      .then((data) => setConsultingTitles(data.map((item) => item.title)))
      .catch((error) => console.error("Error fetching consulting titles:", error));
  }, []);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setDropdownOpen(false);
    }, 300); 
  };

  const handleOurTeamClick = () => {
    if (location.pathname === "/") {
      const ourTeamSection = document.getElementById("our-team-section");
      if (ourTeamSection) {
        ourTeamSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/", { replace: false });
      setTimeout(() => {
        const ourTeamSection = document.getElementById("our-team-section");
        if (ourTeamSection) {
          ourTeamSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 300);
    }
  };

  const handleAddPost = () => {
    navigate("/admin/addpost");
  };

  const navItems = [
    { name: "AI Marketplace", externalLink: "https://fpinfinity.xyz/" },
    { name: "Consulting", href: "/consulting" },
    { name: "Market Education", href: "/research-overviews" },
    { name: "Our Team", action: handleOurTeamClick },
  ];

  const renderAuthButtons = () => {
    if (isAuthenticated) {
      return (
        <div className="flex items-center space-x-4">
          {isSuperUser && (
            <button
              onClick={handleAddPost}
              className="px-4 py-2 bg-[#2887BF] text-white rounded-lg hover:bg-blue-900 transition duration-300"
            >
              Add Post
            </button>
          )}
          <button
            onClick={handleLogout}
            className="px-4 py-2 bg-[#2887BF] text-white rounded-lg hover:bg-blue-900 transition duration-300"
          >
            Logout
          </button>
        </div>
      );
    } else {
      return (
        <div className="flex items-center space-x-4">
          <button
            onClick={() => navigate("/login")}
            className="px-4 py-2 bg-[#2887BF] text-white rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Login
          </button>
          <button
            onClick={() => navigate("/register")}
            className="px-4 py-2 bg-[#2887BF] text-white rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Sign Up
          </button>
        </div>
      );
    }
  };

  return (
    <div
      className={`max-w-full fixed top-0 left-0 right-0 z-50 ${
        sticky ? "shadow-md bg-[#0D203B] text-white" : "bg-transparent"
      } transition-all duration-300 ease-in-out`}
    >
      <div className="flex items-center justify-between py-4 px-4 md:px-10 lg:px-20 text-white">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate("/")}
        >
          <span className="text-4xl 2xl:text-6xl font-bold px-2">FP8</span>
        </div>

        <div className="hidden lg:flex lg:space-x-6 2xl:text-3xl">
          {navItems.map((item) =>
            item.externalLink ? (
              <a
                key={item.name}
                href={item.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded hover:bg-[#112455] transition duration-200 text-white no-underline"
              >
                {item.name}
              </a>
            ) : item.name === "Our Team" ? (
              <button
                key={item.name}
                onClick={item.action}
                className="p-2 rounded hover:bg-[#112455] transition duration-200"
              >
                {item.name}
              </button>
            ) : item.name === "Consulting" ? (
              <div 
                key={item.name} 
                className="flex flex-col items-center group"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="flex items-center relative">
                  <button
                    onClick={() => navigate("/consulting")}
                    className="p-2 rounded hover:bg-[#112455] transition duration-200 flex items-center"
                  >
                    {item.name}
                  </button>
                  <span className="ml-1">▼</span>
                </div>
                <div 
                  className={`absolute top-20 bg-[#112455] text-white mt-2 w-64 2xl:w-[30rem] shadow-lg rounded-lg transition-opacity duration-200 ${
                    dropdownOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
                  }`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {consultingTitles.map((title, index) => (
                    <a
                      key={index}
                      href={`/consulting#section-${index}`}
                      className="block px-4 py-2 hover:bg-[#112455] text-white no-underline"
                    >
                      {title}
                    </a>
                  ))}
                </div>
              </div>
            ) : (
              <a
                key={item.name}
                onClick={() => navigate(item.href)}
                className="p-2 rounded hover:bg-[#112455] transition duration-200 text-white no-underline cursor-pointer"
              >
                {item.name}
              </a>
            )
          )}
          {renderAuthButtons()}
        </div>

        <div className="lg:hidden">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-4 6h-8"
              />
            </svg>
          </button>
        </div>
      </div>

      {menuOpen && (
        <div className="lg:hidden flex flex-col space-y-2 mt-2 px-4">
          {navItems.map((item) =>
            item.externalLink ? (
              <a
                key={item.name}
                href={item.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded bg-[#112455] hover:bg-[#112455] transition duration-200 text-white no-underline"
              >
                {item.name}
              </a>
            ) : item.name === "Our Team" ? (
              <button
                key={item.name}
                onClick={item.action}
                className="p-2 rounded bg-[#112455] hover:bg-[#112455] transition duration-200 text-white no-underline"
              >
                {item.name}
              </button>
            ) : (
              <a
                key={item.name}
                href={item.href}
                className="p-2 rounded bg-[#112455] hover:bg-[#112455] transition duration-200 text-white no-underline"
              >
                {item.name}
              </a>
            )
          )}
          {renderAuthButtons()}
        </div>
      )}
    </div>
  );
}

export default Navbar;