import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip,LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import Colo1 from './Colo1';
import Colo2 from './Colo11';
import Chatboat from './tcochatboat/Chatboat';

const DataCenterCalculator = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [ownershipType, setOwnershipType] = useState('FULL_OWNERSHIP');
  const [formData, setFormData] = useState({
    //full ownership variables
    buildingOwnership: 'owned',
    leaseRate: 300,
    powerCapacity: 1000,
    coreShellCost: 50,
    coolingApproach: 'air',
    rackCount: 10,
    CPUrackCount: 10,
    GPUrackCount: 20,
    gpuServersPerRack: 8,
    powerDensity: 1,
    serverType: 'cpu',
    networkType: 'Basic_Ethernet',
    operatingSystem: 'linux',
    hpcSoftware: 'none',
    hpcDetails: '',
    itStaffCount: '1-5',
    laborRate: 38,
    facilitiesStaff: false,
    facilitiesStaffCount: 0,
    outsourceHardware: true,
    maintenanceCost: '10000',
    upsContract: false,
    expansion: true,
    expansionTimeline: '1_2years',
    environmentalGoals: true,
    pueTarget: 1.2,
    additionalFees: false,
    customization: false,
    customizationCost: '',
    remoteHands: 'onsite',
    bringOwnHardware: true,
    includeInCost: [],
    gpuBrand: 'NVIDIA',
    gpuModel: 'H200',
    airDistributionType: 'hotColdContainment',
    upsStyle: 'onlineDoubleConversion',
    powerDistributionType: 'basicPanelboards',
    crahCracRedundancy: 'N', 
    pumpsRedundancy: 'N', 
    chillerRedundancy: 'N', 
    heatRejectionRedundancy: 'N',
    itDistributionRedundancy: 'N',
    upsRedundancy: 'N',
    generatorRedundancy: 'N',
  });

  // Enhanced cost calculation constants
  const COST_CONSTANTS = {
    SHELL_COST_PER_SQFT: 250,
    lease_rate_sqft_month: 2.00,
    COOLING_COST_BASE: {
      air: 400,    // $/kW
      liquid: 600, // $/kW
      hybrid: 500  // $/kW
    },
    POWER_DIST_BASE: 400, // $/kW
    UPS_BASE: 200,       // $/kW
    GEN_COST_PER_KW: 300,
    RACK_COST: {
      cpu: 100000,  // $ per rack
      gpu: 300000,
      both: 200000 // $ per rack
    },
    NETWORK_BASE_PER_RACK: 10000,
    infiniBand_multiplier: 2.5,
    Networking_type_multiplier: {
      Basic_Ethernet: 1.0,
      High_speed_interconnect: 2.5,
      Both: 1.5
    },
    POWER_RATE: 0.12,    // $/kWh
    PUE: {
      air: 1.4,
      liquid: 1.2,
      hybrid: 1.3
    },
    GPU_MODEL_COST: {
      A100_PCIe: 165000,
      A100_SXM: 220000,
      H100_PCIe: 300000,
      H100_SXM: 380000,
      H200: 475000,
    },
    redundancy_factor_map: {
      'N': 1.00,
      'N+1': 1.20,
      '2N': 2.00,
      '2(N+1)': 2.20,
    },
    cost_per_kW_ITDist: 200,  
    cost_per_kW_UPS: 200,
    cost_per_kW_Gen: 300,
    cost_per_kW_CRAH: 300,
    cost_per_kW_Pumps: 80,
    cost_per_kW_Chiller: 400,
    cost_per_kW_HeatRejection: 100,

    GPU_MODELS_RATES_YEARLY: {
      A100_PCIe: { rates: [2.0, 1.8, 1.5, 1.2, 0.8] },  // Rates for 2025-2029
      A100_SXM: { rates: [2.5, 2.0, 1.7, 1.3, 0.9] },   // Rates for 2025-2029
      H100_PCIe: { rates: [3.5, 3.0, 2.0, 1.5, 0.7] },  // Rates for 2025-2029
      H100_SXM: { rates: [3.0, 2.5, 1.5, 1.0, 0.3] },   // Rates for 2025-2029
      H200: { rates: [4.0, 3.5, 2.0, 1.5, 0.6] }        // Rates for 2025-2029
    }
    
  };

  const [costs, setCosts] = useState({
    totalCapEx: 0,
    monthlyOpEx: 0,
    hardwareCost: 0,
    staffingCost: 0,
    powerCost: 0,
    revenueData: [],

    //for CapEx breakdown
    coreShellCost: 0,
    powerCoolingCost: 0,
    final_cooling_capex: 0,
    final_power_capex: 0,
    serversCost: 0,
    networkingCost: 0,
    constructionCost: 0,

    //for OpEx breakdown
    powerCost: 0,
    laborCost: 0,
    maintenanceCost: 0,
    softwareCost: 0,
    otherCost: 0,

    //for Redundancy breakdown
    final_power_capex: 0,
    final_cooling_capex: 0,
    final_ITDist: 0,
    final_UPS: 0,
    final_Gen: 0,
    final_CRAH: 0,
    final_Pumps: 0,
    final_Chiller: 0,
    final_HeatRej: 0,

  });

  const calculateEnhancedCosts = () => {
    // ---------------------------
    // 1) Subsystem Redundancy Costs
    // ---------------------------
    const base_ITDist = formData.powerCapacity * COST_CONSTANTS.cost_per_kW_ITDist;
    const base_UPS = formData.powerCapacity * COST_CONSTANTS.cost_per_kW_UPS;
    const base_Gen = formData.powerCapacity * COST_CONSTANTS.cost_per_kW_Gen;
    const base_CRAH = formData.powerCapacity * COST_CONSTANTS.cost_per_kW_CRAH;
    const base_Pumps = formData.powerCapacity * COST_CONSTANTS.cost_per_kW_Pumps;
    const base_Chiller = formData.powerCapacity * COST_CONSTANTS.cost_per_kW_Chiller;
    const base_HeatRej = formData.powerCapacity * COST_CONSTANTS.cost_per_kW_HeatRejection;

    // Apply redundancy factors
    const final_ITDist = base_ITDist * COST_CONSTANTS.redundancy_factor_map[formData.itDistributionRedundancy];
    const final_UPS = base_UPS * COST_CONSTANTS.redundancy_factor_map[formData.upsRedundancy];
    const final_Gen = base_Gen * COST_CONSTANTS.redundancy_factor_map[formData.generatorRedundancy];
    const final_CRAH = base_CRAH * COST_CONSTANTS.redundancy_factor_map[formData.crahCracRedundancy];
    const final_Pumps = base_Pumps * COST_CONSTANTS.redundancy_factor_map[formData.pumpsRedundancy];
    const final_Chiller = base_Chiller * COST_CONSTANTS.redundancy_factor_map[formData.chillerRedundancy];
    const final_HeatRej = base_HeatRej * COST_CONSTANTS.redundancy_factor_map[formData.heatRejectionRedundancy];

    const final_power_capex = final_ITDist + final_UPS + final_Gen;
    const final_cooling_capex = final_CRAH + final_Pumps + final_Chiller + final_HeatRej;

    // ---------------------------
    // 2) Building & Core Systems
    // ---------------------------
    // Cooling base calculation
    const coolingBase = COST_CONSTANTS.COOLING_COST_BASE[formData.coolingApproach];
    const coolingCost = formData.powerCapacity * coolingBase * (formData.airDistributionType === 'hotColdContainment' ? 1.2 : 1.0);

    // Power distribution
    const pdMultiplier = formData.powerDistributionType === 'busway' ? 1.1 : 1.0;
    const powerDistCost = formData.powerCapacity * COST_CONSTANTS.POWER_DIST_BASE * pdMultiplier;

    // UPS calculation
    const upsMultiplier = formData.upsStyle === 'onlineDoubleConversion' ? 1.1 : 0.9;
    const upsCost = formData.powerCapacity * COST_CONSTANTS.UPS_BASE * upsMultiplier;

    // Generator base cost
    const genCost = formData.powerCapacity * COST_CONSTANTS.GEN_COST_PER_KW;

    // ---------------------------
    // 3) IT Hardware
    // ---------------------------
    let hardwareCost = 0;
    if (formData.serverType === 'gpu') {
      const costPerGPUServer = COST_CONSTANTS.GPU_MODEL_COST[formData.gpuModel] || 20000;
      const gpuServersPerRack = formData.gpuServersPerRack;
      const totalGpuServers = formData.GPUrackCount * gpuServersPerRack;
      hardwareCost = costPerGPUServer * totalGpuServers;
    } else if (formData.serverType === 'both') {
      hardwareCost = COST_CONSTANTS.RACK_COST.both * (formData.CPUrackCount + formData.GPUrackCount);
    } else {
      hardwareCost = COST_CONSTANTS.RACK_COST.cpu * formData.CPUrackCount;
    }

    // ---------------------------
    // 4) Networking
    // ---------------------------
    const netMultiplier = COST_CONSTANTS.Networking_type_multiplier[formData.networkType] || 1.0;
    const networkingCost = formData.rackCount * COST_CONSTANTS.NETWORK_BASE_PER_RACK * netMultiplier;

    // ---------------------------
    // revenue
    // ---------------------------

    // Revenue calculation inputs
    const years = 5; // Projection for 5 years
    const utilizationRate = 0.7; // 70% utilization
    const hoursPerDay = 24; // Total hours per day
    const daysPerMonth = 30; // Approximation for monthly revenue
    const deployedGPUs = formData.GPUrackCount * formData.gpuServersPerRack;

    let revenueData = [];

    // Check if the gpuModel exists in COST_CONSTANTS
    const gpuModelRates =
      COST_CONSTANTS.GPU_MODELS_RATES_YEARLY[formData.gpuModel]?.rates;

    if (!gpuModelRates) {
      throw new Error(`Rates not found for GPU model: ${formData.gpuModel}`);
    }

    for (let year = 0; year < years; year++) {
      let yearlyRevenue = 0;

      // Select the rate for the current year or use the last rate if the year exceeds rate array length
      const hourlyRate = gpuModelRates[Math.min(year, gpuModelRates.length - 1)];

      // Calculate revenue for the current GPU model
      const monthlyRevenuePerGPU =
        hourlyRate * utilizationRate * hoursPerDay * daysPerMonth;
      const totalMonthlyRevenue = monthlyRevenuePerGPU * deployedGPUs;
      yearlyRevenue = totalMonthlyRevenue * 12; // Total for the year

      revenueData.push({
        year: 2025 + year,
        gpuModel: formData.gpuModel, // Include the GPU model in the data
        revenue: yearlyRevenue,
      });
    }

    console.log(revenueData);

    // 5) Total CapEx
    // ---------------------------
    const totalCapEx = (
      final_power_capex +
      final_cooling_capex +
      hardwareCost +
      networkingCost +
      coolingCost +
      powerDistCost +
      upsCost +
      genCost +
      (formData.buildingOwnership === 'owned' ? formData.coreShellCost * COST_CONSTANTS.SHELL_COST_PER_SQFT : 0)
    );

    // ---------------------------
    // 6) OpEx Calculations
    // ---------------------------
    const monthlyKWh = formData.powerCapacity * formData.pueTarget * 720;
    const monthlyPowerCost = monthlyKWh * COST_CONSTANTS.POWER_RATE;

    const staffCountMap = { '1-5': 3, '6-10': 8, '10+': 12 };
    const staffCount = staffCountMap[formData.itStaffCount] || 3;
    const monthlyLaborCost = staffCount * formData.laborRate * 160;

    let monthlyMaintenance = 0;
    if (formData.outsourceHardware) {
      monthlyMaintenance = formData.maintenanceCost ? parseFloat(formData.maintenanceCost) / 12 : (totalCapEx * 0.07) / 12;
    }

    const monthly_lease_cost = formData.buildingOwnership === 'leased' ? 
      formData.coreShellCost * COST_CONSTANTS.lease_rate_sqft_month * formData.leaseRate : 0;

    const monthlyOpEx = monthlyPowerCost + monthlyLaborCost + monthlyMaintenance + monthly_lease_cost;

    // Update costs state
    setCosts({
      totalCapEx,
      monthlyOpEx,
      hardwareCost,
      staffingCost: monthlyLaborCost,
      powerCost: monthlyPowerCost,
      revenueData,

      // For CapEx breakdown
      coreShellCost: formData.buildingOwnership === 'owned' ? formData.coreShellCost * COST_CONSTANTS.SHELL_COST_PER_SQFT : 0,
      powerCoolingCost: final_power_capex + final_cooling_capex,
      final_power_capex,
      final_cooling_capex,
      serversCost: hardwareCost,
      networkingCost,
      constructionCost: 0,

      // For OpEx breakdown
      powerCost: monthlyPowerCost,
      laborCost: monthlyLaborCost,
      maintenanceCost: monthlyMaintenance,
      softwareCost: 1500,
      otherCost: monthly_lease_cost,

      // For Redundancy breakdown
      final_power_capex,
      final_cooling_capex,
      final_ITDist,
      final_UPS,
      final_Gen,
      final_CRAH,
      final_Pumps,
      final_Chiller,
      final_HeatRej,
    });
    console.log(totalCapEx);
    console.log(monthlyOpEx);
  };

  useEffect(calculateEnhancedCosts, [formData, ownershipType]);


  const handleITDistributionChange = (value) => {
    const newFormData = {
      ...formData,
      itDistributionRedundancy: value,
      upsRedundancy: 'N',
      generatorRedundancy: 'N'
    };
    setFormData(newFormData);
  };
  const handleUPSChange = (value) => {
    let generatorValue = formData.generatorRedundancy;
    
    // Ensure generator redundancy is compatible with UPS redundancy
    if (value === 'N') generatorValue = 'N';
    else if (value === 'N+1') generatorValue = 'N+1';
    else if (value === '2N') generatorValue = '2N';
    else if (value === '2(N+1)') generatorValue = '2(N+1)';
    
    setFormData({
      ...formData,
      upsRedundancy: value,
      generatorRedundancy: generatorValue
    });
  };
  const handleCRAHChange = (value) => {
    let pumpsValue = formData.pumpsRedundancy;
    let chillerValue = formData.chillerRedundancy;
    let heatRejectionValue = formData.heatRejectionRedundancy;
    
    // Set default values based on CRAH/CRAC selection
    if (value === 'N') {
      pumpsValue = 'N';
      chillerValue = 'N';
      heatRejectionValue = 'N';
    } else if (value === '2N') {
      pumpsValue = '2N';
      chillerValue = '2N';
      heatRejectionValue = '2N';
    } else if (value === 'N+1') {
      pumpsValue = 'N';
      chillerValue = 'N';
      heatRejectionValue = 'N';
    }
    
    setFormData({
      ...formData,
      crahCracRedundancy: value,
      pumpsRedundancy: pumpsValue,
      chillerRedundancy: chillerValue,
      heatRejectionRedundancy: heatRejectionValue
    });
  };
  const renderFullOwnership = () => (
    <div className="space-y-2 text-xs bg-blue-500 bg-opacity-30 rounded-lg shadow-lg p-6">
      {/* Section 3.A: Site & Facilities */}
      <section>
        <div className='bg-blue-500 bg-opacity-20 p-1 rounded-lg'>
          <h3 className="text-sm 2xl:text-[1.7rem] font-bold mb-1 2xl:p-2">A: Site & Facilities</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center text-xs"> {/* Text size adjusted */}
          <div className="flex items-center">
            <label className="text-xs 2xl:text-[1.5rem] w-1/3">Building Ownership</label>
            <div className="flex gap-1 w-2/3"> {/* Adjusted gap */}
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-xs"
                  value="owned"
                  checked={formData.buildingOwnership === 'owned'}
                  onChange={() =>
                    setFormData({ ...formData, buildingOwnership: 'owned' })
                  }
                />
                <span className="ml-1 2xl:ml-2 text-xs 2xl:text-[1.5rem]">Owned</span>
              </label>
              <label className="inline-flex items-center 2xl:ml-2">
                <input
                  type="radio"
                  className="form-radio text-xs"
                  value="leased"
                  checked={formData.buildingOwnership === 'leased'}
                  onChange={() =>
                    setFormData({ ...formData, buildingOwnership: 'leased' })
                  }
                />
                <span className="ml-1 2xl:ml-2 text-xs 2xl:text-[1.5rem]">Leased</span>
              </label>
            </div>
          </div>
          {formData.buildingOwnership === 'leased' && (
            <div className="flex items-center mt-2">
              <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">Lease Rate</label>
              <input
                type="number"
                className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10 "
                value={formData.leaseRate}
                onChange={(e) =>
                  setFormData({ ...formData, leaseRate: e.target.value })
                }
              />
            </div>
          )}
          {formData.buildingOwnership === 'owned' && (
            <div className="2xl:mt-1">
              <label className="block mb-2 2xl:mt-2">
                <span className="text-xs 2xl:text-[1.5rem]">Core & Shell (ft²):</span>
                <input
                  type="range"
                  min="0"
                  max="300"
                  step="10"
                  value={formData.coreShellCost}
                  onChange={(e) =>
                    setFormData({ ...formData, coreShellCost: Number(e.target.value) })
                  }
                  className="mt-1 block w-full text-xs 2xl:text-[1.5rem]"
                />
                <div className="text-xs 2xl:text-[1.5rem]">{formData.coreShellCost} ft²</div>
              </label>
            </div>
          )}
          <div>
            <label className="block mb-2">
              <span className="text-xs 2xl:text-[1.5rem]">Power Needed (MW):</span>
              <div className="flex items-center gap-2">
                {/* Range Slider */}
                <input
                  type="range"
                  min="0.1"
                  max="1000"
                  step="0.1"
                  value={formData.powerCapacity / 1000}
                  onChange={(e) => {
                    const powerCapacity = Number(e.target.value) * 1000;
                    const rackCount = Math.ceil(powerCapacity / formData.powerDensity);

                    // Update formData with CPU or GPU rack count based on serverType
                    if (formData.serverType === 'cpu') {
                      setFormData({
                        ...formData,
                        powerCapacity,
                        CPUrackCount: rackCount,
                      });
                    } else if (formData.serverType === 'gpu') {
                      setFormData({
                        ...formData,
                        powerCapacity,
                        GPUrackCount: rackCount,
                      });
                    }
                  }}
                  className="mt-1 flex-1"
                />

                {/* Input Field */}
                <input
                  type="number"
                  min="0.1"
                  max="1000"
                  step="0.1"
                  value={formData.powerCapacity / 1000}
                  onChange={(e) => {
                    const powerCapacity = Number(e.target.value) * 1000;
                    if (powerCapacity >= 100 && powerCapacity <= 1000000) {
                      const rackCount = Math.ceil(powerCapacity / formData.powerDensity);

                      // Update formData with CPU or GPU rack count based on serverType
                      if (formData.serverType === 'cpu') {
                        setFormData({
                          ...formData,
                          powerCapacity,
                          CPUrackCount: rackCount,
                        });
                      } else if (formData.serverType === 'gpu') {
                        setFormData({
                          ...formData,
                          powerCapacity,
                          GPUrackCount: rackCount,
                        });
                      }
                    }
                  }}
                  className="w-24 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                />
              </div>
              <div className="text-xs 2xl:text-[1.5rem] mt-1">
                {(formData.powerCapacity / 1000).toLocaleString()} MW
              </div>
            </label>
          </div>

          <div className="flex items-center">
            <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">Cooling Approach</label>
            <select
              className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10 2xl:bg-gray-500 2xl:bg-opacity-10"
              value={formData.coolingApproach}
              onChange={(e) =>
                setFormData({ ...formData, coolingApproach: e.target.value })
              }
            >
              <option value="air" className="bg-gray-500 ">Air-cooled</option>
              <option value="liquid" className="bg-gray-500 ">Liquid-cooled</option>
              <option value="hybrid" className="bg-gray-500">Hybrid</option>
            </select>
          </div>


          <div className="flex items-center 2xl:mt-1">
            <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">Air Distribution Type</label>
            <select
              className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              value={formData.airDistributionType}
              onChange={(e) =>
                setFormData({ ...formData, airDistributionType: e.target.value })
              }
            >
              <option value="hotColdContainment" className="bg-gray-500">Hot-Aisle/Cold-Aisle Containment</option>
              <option value="rowBased" className="bg-gray-500">Row-Based Cooling</option>
              <option value="perimeter" className="bg-gray-500">Perimeter Cooling</option>
              <option value="other" className="bg-gray-500">Other / Custom</option>
            </select>
          </div>

          <div className="flex items-center">
            <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">UPS Style</label>
            <select
              className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              value={formData.upsStyle}
              onChange={(e) =>
                setFormData({ ...formData, upsStyle: e.target.value })
              }
            >
              <option value="onlineDoubleConversion" className="bg-gray-500">Online Double-Conversion</option>
              <option value="lineInteractive" className="bg-gray-500">Line-Interactive</option>
              <option value="offline" className="bg-gray-500">Offline/Standby</option>
              <option value="other" className="bg-gray-500">Other</option>
            </select>
          </div>

          <div className="flex items-center 2xl:mt-1 2xl:mb-1">
            <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[1.5rem] font-bold w-1/3 pr-1">Power Distribution Type</label>
            <select
              className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              value={formData.powerDistributionType}
              onChange={(e) =>
                setFormData({ ...formData, powerDistributionType: e.target.value })
              }
            >
              <option value="basicPanelboards" className="bg-gray-500">Basic Wall-Mount Panelboards</option>
              <option value="busway" className="bg-gray-500">Busway / Overhead Distribution</option>
              <option value="floorPDU" className="bg-gray-500">Floor PDU boxes</option>
              <option value="other" className="bg-gray-500">Other</option>
            </select>
          </div>
        </div>
      </section>

      {/* Section 3.B: Hardware  and Software & Licensing*/}
      <section className="2xl:pt-2">
        <div className='bg-blue-500 bg-opacity-20 p-1 rounded-lg'>
          <h3 className="text-sm 2xl:text-[1.7rem] font-bold mb-1 2xl:p-2">B: Hardware and Software & Licensing</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center text-xs"> {/* Text size adjusted */}
          <div className="2xl:mt-2">
              {/* Number of Racks */}
              <div>
                <label className="block mb-2">
                  <span className="2xl:text-[1.5rem]">Number of Racks:</span>
                  <div className="flex items-center gap-4">
                    <div className="text-xs 2xl:text-[1.5rem] bg-gray-500 bg-opacity-10 rounded px-2 py-1">
                      {formData.serverType === 'cpu'
                        ? `${formData.CPUrackCount} racks`
                        : `${formData.GPUrackCount} racks`}
                    </div>

                    {/* GPU Servers per Rack */}
                    <label className="block">
                      <span className="2xl:text-[1.5rem] 2xl:leading-[1.5rem]">GPU Servers per Rack:</span>
                      <input
                        type="number"
                        min="1"
                        value={formData.gpuServersPerRack || 8} // Default value is 8
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            gpuServersPerRack: Number(e.target.value),
                          })
                        }
                        className="w-16 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10 ml-4"
                      />
                    </label>
                  </div>
                </label>
              </div>

              {/* Server Type */}
              <div className="2xl:pt-3">
                <label className="block 2xl:text-[1.5rem]">Server Type:</label>
                {['CPU-only servers', 'GPU/HPC nodes', 'Mix of both'].map((type) => (
                  <label key={type} className="inline-flex items-center mr-2 2xl:pt-4">
                    <input
                      type="checkbox"
                      className="form-checkbox text-xs 2xl:text-[1.5rem]"
                      checked={
                        (type === 'CPU-only servers' && formData.serverType === 'cpu') ||
                        (type === 'GPU/HPC nodes' && formData.serverType === 'gpu') ||
                        (type === 'Mix of both' && formData.serverType === 'both')
                      }
                      onChange={() => {
                        let serverTypeValue;
                        let rackCountValue;

                        if (type === 'CPU-only servers') {
                          serverTypeValue = 'cpu';
                          rackCountValue = formData.CPUrackCount;
                        } else if (type === 'GPU/HPC nodes') {
                          serverTypeValue = 'gpu';
                          rackCountValue = formData.GPUrackCount;
                        } else if (type === 'Mix of both') {
                          serverTypeValue = 'both';
                          rackCountValue = Math.max(
                            formData.CPUrackCount,
                            formData.GPUrackCount
                          ); // Example: Mix logic could vary
                        }

                        setFormData({
                          ...formData,
                          serverType: serverTypeValue,
                          rackCount: rackCountValue,
                        });
                      }}
                    />
                    <span className="ml-1 text-xs 2xl:text-[1.5rem]">{type}</span>
                  </label>
                ))}
              </div>
            </div>
          <div>
          <label className="block mb-2">
              <span className="2xl:text-[1.5rem]">Power Density (kW/rack):</span>
              <input
                type="range"
                min="1"
                max="150"
                step="1"
                value={formData.powerDensity}
                onChange={(e) => {
                  const powerDensity = Number(e.target.value);
                  const rackCount = Math.ceil(formData.powerCapacity / powerDensity);

                  // Update formData with CPU or GPU rack count based on serverType
                  if (formData.serverType === 'cpu') {
                    setFormData({
                      ...formData,
                      powerDensity,
                      CPUrackCount: rackCount,
                    });
                  } else if (formData.serverType === 'gpu') {
                    setFormData({
                      ...formData,
                      powerDensity,
                      GPUrackCount: rackCount,
                    });
                  }
                }}
                className="mt-1 block w-full text-xs 2xl:text-[1.5rem]"
              />
              <div className="text-xs 2xl:text-[1.5rem] 2xl:mt-2">
                {formData.powerDensity} kW/rack
              </div>
            </label>
          </div>
          {/* Conditional Rendering for GPU Brand and GPU Model */}
          {formData.serverType === 'gpu' && (
            <>
              <div>
                <label className="block mb-2 2xl:mt-3">
                  <span className="2xl:text-[1.5rem]">GPU Brand</span>
                  <select
                    className="mt-1 block w-full p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                    value={formData.gpuBrand}
                    onChange={(e) =>
                      setFormData({ ...formData, gpuBrand: e.target.value })
                    }
                  >
                    <option value="NVIDIA" className="bg-gray-500">NVIDIA</option>
                  </select>
                </label>
              </div>

              <div>
                <label className="block mb-2">
                  <span className="2xl:text-[1.5rem]">GPU Model</span>
                  <select
                    className="mt-1 block w-full p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                    value={formData.gpuModel}
                    onChange={(e) =>
                      setFormData({ ...formData, gpuModel: e.target.value })
                    }
                  >
                    <option value="A100_PCIe" className="bg-gray-500">A100-PCIe</option>
                    <option value="A100_SXM" className="bg-gray-500">A100-SXM</option>
                    <option value="H100_PCIe" className="bg-gray-500">H100-PCIe</option>
                    <option value="H100_SXM" className="bg-gray-500">H100-SXM</option>
                    <option value="H200" className="bg-gray-500">H200</option>
                  </select>
                </label>
              </div>
            </>
          )}
          <div>
            <label className="block 2xl:text-[1.5rem] 2xl:leading-[2rem]">Networking:</label>
            {['Basic Ethernet (1/10/25 GbE)', 'High-speed interconnect', 'Mix of both'].map(
              (networkType) => (
                <label key={networkType} className="inline-flex items-center mr-2">
                  <input
                    type="checkbox"
                    className="form-checkbox text-xs 2xl:text-[1.5rem]"
                    checked={
                      (networkType === 'Basic Ethernet (1/10/25 GbE)' && formData.networkType === 'Basic_Ethernet') ||
                      (networkType === 'High-speed interconnect' && formData.networkType === 'High_speed_interconnect') ||
                      (networkType === 'Mix of both' && formData.networkType === 'Both')
                    }
                    onChange={() => {
                      let serverTypeValue;
                      if (networkType === 'Basic Ethernet (1/10/25 GbE)') serverTypeValue = 'Basic_Ethernet';
                      else if (networkType === 'High-speed interconnect') serverTypeValue = 'High_speed_interconnect';
                      else if (networkType === 'Mix of both') serverTypeValue = 'Both';

                      setFormData({ ...formData, networkType: serverTypeValue });
                    }}
                  />
                  <span className="ml-1 text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">{networkType}</span>
                </label>
              )
            )}
          </div>
          {/* <div>
            <label className="block">Include in Cost:</label>
            {['Racks', 'Standby Generator', 'Raised Floor', 'Fire Suppression / Detection', 'Switchgear / Panelboards', 'Dropped Ceiling'].map(
              (costItem) => (
                <label key={costItem} className="inline-flex items-center mr-2">
                  <input
                    type="checkbox"
                    className="form-checkbox text-xs"
                    checked={formData.includeInCost.includes(costItem)}
                    onChange={() => {
                      const updatedItems = formData.includeInCost.includes(costItem)
                        ? formData.includeInCost.filter((item) => item !== costItem)
                        : [...formData.includeInCost, costItem];
                      setFormData({ ...formData, includeInCost: updatedItems });
                    }}
                  />
                  <span className="ml-1 text-xs">{costItem}</span>
                </label>
              )
            )}
          </div> */}
          <div>
            <div className="flex items-center py-1">
              <div className="w-1/3 ">
              <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Operating System</label>
              </div>
              <select
                className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                value={formData.operatingSystem}
                onChange={(e) =>
                  setFormData({ ...formData, operatingSystem: e.target.value })
                }
              >
                <option value="linux" className="bg-gray-500">Linux (Ubuntu, RHEL, Rocky, etc.)</option>
                <option value="windows" className="bg-gray-500">Windows</option>
                <option value="other" className="bg-gray-500">Other HPC distro</option>
              </select>
            </div>
            <div className="flex items-center">
              <div className="w-1/3">
              <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">HPC Software</label>
              </div>
              <select
                className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                value={formData.hpcSoftware}
                onChange={(e) =>
                  setFormData({ ...formData, hpcSoftware: e.target.value })
                }
              >
                <option value="none" className="bg-gray-500">None</option>
                <option value="slurm" className="bg-gray-500">Slurm</option>
                <option value="pbs" className="bg-gray-500">PBS</option>
                <option value="lsf" className="bg-gray-500">LSF</option>
                <option value="gridEngine" className="bg-gray-500">Grid Engine</option>
                <option value="other" className="bg-gray-500">Other HPC Scheduler</option>
              </select>
            </div>
          </div>
        </div>
      </section>
  
      {/* Section 4: Staffing & Maintenance */}
      <section>
        <div className='bg-blue-500 bg-opacity-20 p-1 rounded-lg'>
          <h3 className="text-sm 2xl:text-[1.5rem] font-bold mb-1 2xl:p-2">C: Staffing & Maintenance</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center text-xs 2xl:pt-2"> {/* Text size adjusted */}
          <div>
            <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Labor Rate ($/hr)</label>
            <input
              type="number"
              className="w-full p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              placeholder="$38"
              value={formData.laborRate}
              onChange={(e) =>
                setFormData({ ...formData, laborRate: Number(e.target.value) })
              }
            />
          </div>
          <div>
            <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">IT Staff Count</label>
            <select
              className="w-full p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              value={formData.itStaffCount}
              onChange={(e) =>
                setFormData({ ...formData, itStaffCount: e.target.value })
              }
            >
              <option value="1-5" className="bg-gray-500">1–5</option>
              <option value="6-10" className="bg-gray-500">6–10</option>
              <option value="10+" className="bg-gray-500">10+</option>
            </select>
          </div>
          <div>
            <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Facilities Staff Needed?</label>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-xs 2xl:text-[1.5rem]"
                checked={formData.facilitiesStaff}
                onChange={() =>
                  setFormData({
                    ...formData,
                    facilitiesStaff: !formData.facilitiesStaff,
                  })
                }
              />
              <span className="ml-1 text-xs 2xl:text-[1.5rem]">Yes</span>
            </div>
            {formData.facilitiesStaff && (
              <div className="mt-2">
                <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Number of Facilities Staff</label>
                <input
                  type="number"
                  className="w-full p-1 text-xs 2xl:text-[1.5rem]   border rounded bg-gray-500 bg-opacity-10"
                  value={formData.facilitiesStaffCount}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      facilitiesStaffCount: Number(e.target.value),
                    })
                  }
                />
              </div>
            )}
          </div>
          <div>
            <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Outsource Hardware Maintenance?</label>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-xs 2xl:text-[1.5rem]"
                checked={formData.outsourceHardware}
                onChange={() =>
                  setFormData({
                    ...formData,
                    outsourceHardware: !formData.outsourceHardware,
                  })
                }
              />
              <span className="ml-1 text-xs 2xl:text-[1.5rem]">Yes</span>
            </div>
            {formData.outsourceHardware && (
              <div className="mt-2">
                <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Approx. Annual Contract Cost</label>
                <input
                  type="number"
                  className="w-full p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                  placeholder="Enter amount eg $300K/year"
                  value={formData.maintenanceCost}
                  onChange={(e) =>
                    setFormData({ ...formData, maintenanceCost: e.target.value })
                  }
                />
              </div>
            )}
          </div>
          <div>
            <label className="block 2xl:text-[1.5rem] 2xl:leading-[2rem]">UPS & Generator Maintenance:</label>
            <div className="flex items-center 2xl:mb-2">
              <input
                type="checkbox"
                className="form-checkbox text-xs 2xl:text-[1.5rem]"
                checked={formData.upsContract}
                onChange={() =>
                  setFormData({ ...formData, upsContract: !formData.upsContract })
                }
              />
              <span className="ml-1 text-xs 2xl:text-[1.5rem]">I have a maintenance contract in place</span>
            </div>
          </div>
        </div>
      </section>

      <section>
      <div className="">
      <div className="bg-blue-500 bg-opacity-20 p-1 rounded-lg">
        <h3 className="text-sm 2xl:text-[1.5rem] 2xl:leading-[2rem] font-bold mb-1">D: Redundancy Levels</h3>
      </div>
      
      <div className="space-y-4">
        {/* Power Redundancy Section */}
        <div className="flex items-center space-x-4">
          <label className="block w-32 2xl:text-[1.5rem] 2xl:leading-[2rem]">Power Redundancy</label>
          
          <div className="flex flex-col items-center">
            <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">IT Distribution</label>
            <select
              className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10" 
              value={formData.itDistributionRedundancy}
              onChange={(e) => handleITDistributionChange(e.target.value)}
            >
              <option value="N" className="bg-gray-500">N</option>
              <option value="2N" className="bg-gray-500">2N</option>
            </select>
          </div>

          <div className="flex flex-col items-center">
            <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">UPS</label>
            <select
              className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
              value={formData.upsRedundancy}
              onChange={(e) => handleUPSChange(e.target.value)}
            >
              {formData.itDistributionRedundancy === 'N' ? (
                <>
                  <option value="N" className="bg-gray-500">N</option>
                  <option value="N+1" className="bg-gray-500">N+1</option>
                </>
              ) : (
                <>
                  <option value="N" className="bg-gray-500">N</option>
                  <option value="N+1" className="bg-gray-500">N+1</option>
                  <option value="2N" className="bg-gray-500">2N</option>
                  <option value="2(N+1)" className="bg-gray-500">2(N+1)</option>
                </>
              )}
            </select>
          </div>

          <div className="flex flex-col items-center">
            <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">Generator</label>
            <select
              className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
              value={formData.generatorRedundancy}
              onChange={(e) => setFormData({ ...formData, generatorRedundancy: e.target.value })}
            >
              {formData.upsRedundancy === 'N' && <option value="N" className="bg-gray-500">N</option>}
              {formData.upsRedundancy === 'N+1' && (
                <>
                  <option value="N" className="bg-gray-500">N</option>
                  <option value="N+1" className="bg-gray-500">N+1</option>
                </>
              )}
              {formData.upsRedundancy === '2N' && (
                <>
                  <option value="N+1" className="bg-gray-500">N+1</option>
                  <option value="2N" className="bg-gray-500">2N</option>
                </>
              )}
              {formData.upsRedundancy === '2(N+1)' && (
                <>
                  <option value="N+1" className="bg-gray-500">N+1</option>
                  <option value="2N" className="bg-gray-500">2N</option>
                  <option value="2(N+1)" className="bg-gray-500">2(N+1)</option>
                </>
              )}
            </select>
          </div>
        </div>

        {/* Cooling Redundancy Section */}
        <div className="flex items-center space-x-4">
          <label className="block w-32 2xl:text-[1.5rem] 2xl:leading-[2rem]">Cooling Redundancy</label>
          
          <div className="flex flex-col items-center">
            <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">CRAH/CRAC</label>
            <select
              className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
              value={formData.crahCracRedundancy}
              onChange={(e) => handleCRAHChange(e.target.value)}
            >
              <option value="N" className="bg-gray-500">N</option>
              <option value="N+1" className="bg-gray-500">N+1</option>
              <option value="2N" className="bg-gray-500">2N</option>
            </select>
          </div>

          <div className="flex flex-col items-center">
            <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">Pumps</label>
            <select
              className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
              value={formData.pumpsRedundancy}
              onChange={(e) => setFormData({ ...formData, pumpsRedundancy: e.target.value })}
            >
              {formData.crahCracRedundancy === '2N' ? (
                <>
                  <option value="2N" className="bg-gray-500">2N</option>
                  <option value="2(N+1)" className="bg-gray-500">2(N+1)</option>
                </>
              ) : (
                <>
                  <option value="N" className="bg-gray-500">N</option>
                  <option value="N+1" className="bg-gray-500">N+1</option>
                </>
              )}
            </select>
          </div>

          <div className="flex flex-col items-center">
            <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">Chiller</label>
            <select
              className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
              value={formData.chillerRedundancy}
              onChange={(e) => setFormData({ ...formData, chillerRedundancy: e.target.value })}
            >
              {formData.crahCracRedundancy === '2N' ? (
                <>
                  <option value="2N" className="bg-gray-500">2N</option>
                  <option value="2(N+1)" className="bg-gray-500">2(N+1)</option>
                </>
              ) : (
                <>
                  <option value="N" className="bg-gray-500">N</option>
                  <option value="N+1" className="bg-gray-500">N+1</option>
                </>
              )}
            </select>
          </div>

          <div className="flex flex-col items-center">
            <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">Heat Rejection</label>
            <select
              className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
              value={formData.heatRejectionRedundancy}
              onChange={(e) => setFormData({ ...formData, heatRejectionRedundancy: e.target.value })}
            >
              {formData.crahCracRedundancy === '2N' ? (
                <>
                  <option value="2N" className="bg-gray-500">2N</option>
                  <option value="2(N+1)" className="bg-gray-500">2(N+1)</option>
                </>
              ) : (
                <>
                  <option value="N" className="bg-gray-500">N</option>
                  <option value="N+1" className="bg-gray-500">N+1</option>
                </>
              )}
            </select>
          </div>
        </div>
      </div>
    </div>
      </section>

      {/* Section 5: Additional Details */}
      <section>
        <div className='bg-blue-500 bg-opacity-20 p-1 rounded-lg'>
          <h3 className="text-sm 2xl:text-[1.5rem] 2xl:leading-[2rem] font-bold mb-1">E: Additional Considerations</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center text-xs"> {/* Text size adjusted */}
          <div>
            <label className="block 2xl:text-[1.5rem] 2xl:leading-[2rem]">Future Expansion Planned?</label>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-xs 2xl:text-[1.5rem]"
                checked={formData.expansion}
                onChange={() =>
                  setFormData({ ...formData, expansion: !formData.expansion })
                }
              />
              <span className="ml-1 text-xs 2xl:text-[1.5rem]">Yes</span>
            </div>
            {formData.expansion && (
              <div className="mt-2">
                <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Approximate Timeline</label>
                <select
                  className="w-full p-1 text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] border rounded bg-gray-500 bg-opacity-10"
                  value={formData.expansionTimeline}
                  onChange={(e) =>
                    setFormData({ ...formData, expansionTimeline: e.target.value })
                  }
                >
                  <option value="1_2years" className="bg-gray-500">1–2 years</option>
                  <option value="3_5years" className="bg-gray-500">3–5 years</option>
                  <option value="5_plusyears" className="bg-gray-500">5+ years</option>
                </select>
              </div>
            )}
          </div>
          <div>
            <label className="block 2xl:text-[1.5rem] 2xl:leading-[2rem]">Environmental / Efficiency Goals?</label>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-xs 2xl:text-[1.5rem]"
                checked={formData.environmentalGoals}
                onChange={() =>
                  setFormData({
                    ...formData,
                    environmentalGoals: !formData.environmentalGoals,
                  })
                }
              />
              <span className="ml-1 text-xs 2xl:text-[1.5rem]">Yes</span>
            </div>
            {formData.environmentalGoals && (
              <div className="mt-2">
                <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Target PUE or Renewable Energy Goal</label>
                <input
                  type="text"
                  className="w-full p-1 border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
                  placeholder="Enter target"
                  value={formData.pueTarget}
                  onChange={(e) =>
                    setFormData({ ...formData, pueTarget: e.target.value })
                  }
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

  const capExData = [
    { name: 'Core & Shell', value: costs.coreShellCost },
    { name: 'Power', value: costs.final_power_capex },
    { name: 'Cooling', value: costs.final_cooling_capex },
    { name: 'Servers', value: costs.serversCost },
    { name: 'Networking', value: costs.networkingCost },
    { name: 'Construction', value: costs.constructionCost },
  ];

  const opExData = [
    { name: 'Power', value: costs.powerCost },
    { name: 'Labor', value: costs.laborCost },
    { name: 'Maintenance', value: costs.maintenanceCost },
    { name: 'Software', value: costs.softwareCost },
    { name: 'Other', value: costs.otherCost }
  ];


  const powerData = [
    { name: 'IT Distribution', value: costs.final_ITDist },
    { name: 'UPS', value: costs.final_UPS },
    { name: 'Generator', value: costs.final_Gen },
  ];
  const coolingData = [
    { name: 'CRAH/CRAC', value: costs.final_CRAH },
    { name: 'Pumps', value: costs.final_Pumps },
    { name: 'Chiller', value: costs.final_Chiller },
    { name: 'Heat Rejection', value: costs.final_HeatRej }
  ];
  const totalRedundancyData = [
    { name: 'Power', value: costs.final_power_capex },
    { name: 'Cooling', value: costs.final_cooling_capex }
  ];


  
// Calculate OpEx and Revenue Data for Analysis
const calculateOpExAndRevenueData = () => {
  const baseYear = 2025;
  let years = 0;
  const expansionTimeline = formData.expansionTimeline;
  if (expansionTimeline === '1_2years') {
    years = 3;
  } else if (expansionTimeline === '3_5years') {
    years = 5;
  } else if (expansionTimeline === '5_plusyears') {
    years = 7;
  }

  const annualOpExGrowth = 1.07; // 7% annual increase

  // Map revenueData to an object for quick lookup
  const revenueMap = costs.revenueData.reduce((acc, item) => {
    acc[item.year] = item.revenue;
    return acc;
  }, {});

  return Array.from({ length: years }, (_, index) => {
    const year = baseYear + index;
    const yearlyOpEx = costs.monthlyOpEx * 12 * Math.pow(annualOpExGrowth, index);

    return {
      year: year.toString(),
      OpEx: yearlyOpEx,
      Revenue: revenueMap[year] || 0, 
    };
  });
};

const opExAndRevenueData = calculateOpExAndRevenueData();


// Calculate yearly CapEx
const calculateCapExData = () => {
  const baseYear = 2025;
  let years = 0;
  const expansionTimeline = formData.expansionTimeline;
  if (expansionTimeline === '1_2years') {
    years = 3;
  } else if (expansionTimeline === '3_5years') {
    years = 5;
  } else if (expansionTimeline === '5_plusyears') {
    years = 7;
  }

  return Array.from({ length: years }, (_, index) => {
    const year = baseYear + index;
    return {
      year: year.toString(),
      CapEx: index === 0 ? costs.totalCapEx.toLocaleString() : 0 
    };
  });
};

// Merge OpEx, Revenue, and CapEx data into a single table data structure
const generateTableData = () => {
  const capExYears = calculateCapExData();
  return opExAndRevenueData.map((item, index) => ({
    year: item.year,
    Revenue: item.Revenue,
    OpEx: item.OpEx,
    CapEx: capExYears[index]?.CapEx || 0, // Use CapEx for the corresponding year
  }));
};

const tableData = generateTableData();


  // Calculate domain for y-axis
  const getYAxisDomain = () => {
    const values = opExAndRevenueData.flatMap(item => [item.OpEx, item.Revenue]);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    const padding = (maxValue - minValue) * 0.1; // Add 10% padding

    return [minValue - padding, maxValue + padding];
  };
  // Function to format numbers with appropriate suffixes
  const formatYAxisValue = (value) => {
    if (Math.abs(value) >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (Math.abs(value) >= 1000) {
      return `$${(value / 1000).toFixed(1)}K`;
    }
    return `$${value.toFixed(0)}`;
  };


  // Custom label for the donut charts
  const renderCustomizedLabel = (data) => ({ cx, cy, midAngle, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 30; // Position the label further outside the pie
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    let y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    // Avoid collision by adjusting y position if labels overlap
    if (index > 0 && y === previousLabelY) {
      y += window.innerWidth >= 2560 ? 40 : 15; // Adjust more on 2xl screens
    }
    previousLabelY = y; // Update the last label position for comparison
  
    return (
      <text
        x={x}
        y={y}
        fill="#f3f4f6"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={window.innerWidth >= 2560 ? '1.5rem' : '12px'}
      >
        {`${data[index].name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };
  let previousLabelY = null;

  function convertToInteger(value) {
    // Check if the value is already an integer
    if (typeof value === 'number') {
      return value; // Return as-is if it's already an integer
    }
  
    // If it's a string, process it
    if (typeof value === 'string') {
      // Check if there are commas
      if (value.includes(',')) {
        return parseInt(value.replace(/,/g, ''), 10); // Remove commas and convert
      } else {
        return parseInt(value, 10); // Convert directly if no commas
      }
    }
  
    // If the input is not a string or number, return NaN
    return NaN;
  }

  const calculateProfitData = () => {
    return tableData.map((row, index) => {
      return {
        year: row.year,
        Profit: convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx))
      };
    });
  };
  
  const coloProfit = calculateProfitData();


  const StackedInfrastructureChart = ({ powerData, coolingData, totalRedundancyData }) => {
    const stackedData = [
      {
        category: 'Total Power',
        total: totalRedundancyData.reduce((sum, item) => sum + item.value, 0),
        'Power': totalRedundancyData[0].value,
        'Cooling': totalRedundancyData[1].value
      },      
      {
        category: 'Cooling Systems',
        total: coolingData.reduce((sum, item) => sum + item.value, 0),
        'CRAH/CRAC': coolingData[0].value,
        'Pumps': coolingData[1].value,
        'Chiller': coolingData[2].value,
        'Heat Rejection': coolingData[3].value
      },
      {
        category: 'Power Systems',
        total: powerData.reduce((sum, item) => sum + item.value, 0),
        'IT Distribution': powerData[0].value,
        'UPS': powerData[1].value,
        'Generator': powerData[2].value
      }    
    ];
  
    // Custom tooltip content
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="bg-white p-2 border border-gray-200 rounded shadow">
            <p className="text-black font-medium mb-1">
              Total: ${stackedData.find(item => item.category === label).total.toLocaleString()}
            </p>
            {payload.map((entry, index) => (
              <p key={index} style={{ color: entry.color }}>
                {entry.name}: ${entry.value.toLocaleString()}
              </p>
            ))}
          </div>
        );
      }
      return null;
    };

    return (
      <div className="h-48 2xl:h-[86]">
        <div className='bg-blue-500 bg-opacity-20 rounded-lg pt-1 pb-1 px-3 mb-2'>
        <h4 className="text-lg 2xl:text-[1.5rem] font-medium mb-2">Infrastructure Systems Distribution</h4>
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart 
            data={stackedData}
            barSize={60}
            maxBarSize={80}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="category" 
              axisLine={true}
              interval={0}
              tickLine={false}
              padding={{ left: 50, right: 50 }}
            />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            
            {/* Power Systems Stack */}
            <Bar dataKey="IT Distribution" stackId="power" fill="#0088FE" />
            <Bar dataKey="UPS" stackId="power" fill="#00C49F" />
            <Bar dataKey="Generator" stackId="power" fill="#FFBB28" />
            
            {/* Cooling Systems Stack */}
            <Bar dataKey="CRAH/CRAC" stackId="cooling" fill="#FF8042" />
            <Bar dataKey="Pumps" stackId="cooling" fill="#8884d8" />
            <Bar dataKey="Chiller" stackId="cooling" fill="#82ca9d" />
            <Bar dataKey="Heat Rejection" stackId="cooling" fill="#ffc658" />
            
            {/* System Redundancy Stack */}
            <Bar dataKey="Power" stackId="redundancy" fill="#FF99CC" />
            <Bar dataKey="Cooling" stackId="redundancy" fill="#99CCFF" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const ownershipOptions = [
    { value: 'FULL_OWNERSHIP', label: 'Full Ownership: Hardware + Datacenter Location' },
    { value: 'COLO_1', label: 'Colo: Hardware Only (Rent Datacenter)' },
    { value: 'COLO_2', label: 'Colo: Colocation (Just the Shell, Rent it Out)' }
  ];
  const ownershipDescriptions = {
    FULL_OWNERSHIP: 'This calculator estimates the total cost of owning and operating a datacenter, including building the physical facility, purchasing hardware, and covering ongoing operational expenses like power, cooling, and maintenance. It provides a comprehensive view of costs for organizations seeking full control over their infrastructure.',
    COLO_1: 'This calculator focuses on the costs of owning hardware while renting space in a colocation facility, factoring in expenses like rack space, power consumption, and network fees. It`s ideal for organizations that want to avoid the capital expenditure of building their own datacenter.',
    COLO_2: 'This calculator evaluates the costs and revenue potential of building and operating a datacenter shell, which tenants can rent to host their own hardware. It’s designed for businesses exploring colocation as a service, focusing on infrastructure costs and income from leased space.',
  };

  return (
    <div className="min-h-screen bg-gradient-to-tr from-[#00237D] via-black to-[#00237D] pt-12 px-3 overflow-hidden">
      <div className="max-w-8xl mx-auto pt-10">
        <div className="mb-1">
          <div className="flex w-full gap-1">
            {ownershipOptions.map((option) => (
              <div
                key={option.value}
                onClick={() => setOwnershipType(option.value)}
                className={`
                  flex-1 p-3 text-center cursor-pointer transition-all duration-200
                  ${ownershipType === option.value 
                    ? 'bg-blue-600 bg-opacity-50 translate-y-px shadow-lg' 
                    : 'bg-blue-500 bg-opacity-30 hover:bg-opacity-40'}
                  rounded text-gray-100 2xl:text-[1.5rem] font-medium
                  border-transparent hover:border-blue-400
                `}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>

        {/* Description Section */}
        <div className="bg-blue-500 bg-opacity-20 p-2 rounded-lg w-full mb-1">
          <p className="text-gray-100 text-sm 2xl:text-[1.5rem] 2xl:leading-[2rem]">
            {ownershipDescriptions[ownershipType]}
          </p>
        </div>

        <div className="relative flex flex-row items-stretch gap-3">
          {/* Background Overlay */}
          {isExpanded && (
            <div
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
              style={{ zIndex: 40 }}
              onClick={() => setIsExpanded(false)}
            ></div>
          )}

          {/* Main Content */}
          {ownershipType === 'FULL_OWNERSHIP' && (
            <div className="w-[60%] overflow-hidden text-gray-100">
              {renderFullOwnership()}
            </div>
          )}
          {ownershipType === 'COLO_1' && (
            <div className="w-[60%] flex-1 overflow-hidden text-gray-100">
              <Colo1 />
            </div>
          )}
          {ownershipType === 'COLO_2' && (
            <div className="w-[60%] flex-1 overflow-hidden text-gray-100">
              <Colo2 />
            </div>
          )}
          
          
          {/* Results Container */}
          {ownershipType === 'FULL_OWNERSHIP' && (
            <div 
            className={`absolute transition-all duration-500 ease-in-out rounded-lg shadow-lg p-6  bg-blue-500 bg-opacity-30 text-gray-100
              ${isExpanded ? 'w-[80%] right-0' : 'w-[39%] right-0'}`}
            style={{ 
              zIndex: isExpanded ? 50 : 1,
              height: isExpanded ? '100%' : '100%',
              overflow: isExpanded ? 'auto' : 'visible'
            }}
          >
            {/* Chevron Button */}
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="absolute -left-4 top-1/2 transform -translate-y-1/2 bg-blue-500 rounded-full p-1 ml-4 text-white hover:bg-blue-600 transition-colors z-50 cursor-pointer shadow-lg"
            >
              {isExpanded ? <ChevronRight size={24} /> : <ChevronLeft size={24} />}
            </button>
      
            <div className={`grid ${isExpanded ? 'grid-cols-2' : 'grid-cols-1'} gap-4`}>
              
              {/* Original Charts Section */}
              <div>
                <h2 className="text-xl 2xl:text-[1.5rem] font-bold mb-2">Results</h2>

                {/* Yearly Financial Summary */}
                <div className="h-auto 2xl:h-auto mb-5">
                  <div className="bg-blue-500 bg-opacity-20 rounded-lg mb-5">
                    <div className="flex justify-between items-center px-3 py-1">
                      <h3 className="text-lg 2xl:text-[1.5rem] font-semibold">Yearly Financial Summary</h3>
                    </div>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="min-w-full rounded-lg text-left">
                      <thead>
                        <tr className="bg-blue-300 bg-opacity-30">
                          <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">Year</th>
                          <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">Revenue ($)</th>
                          <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">CapEx ($)</th>
                          <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">OpEx ($)</th>
                          <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">Profit ($)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...tableData, {
                          year: 'Total',
                          Revenue: tableData.reduce((sum, row) => sum + convertToInteger(row.Revenue), 0),
                          CapEx: tableData.reduce((sum, row) => sum + convertToInteger(row.CapEx), 0),
                          OpEx: tableData.reduce((sum, row) => sum + convertToInteger(row.OpEx), 0)
                        }].map((row, index) => {
                          const isTotal = row.year === 'Total';
                          const profit = isTotal 
                            ? row.Revenue - (row.CapEx + row.OpEx)
                            : convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx));
                          
                          return (
                            <tr
                              key={index}
                              className={isTotal 
                                ? "bg-blue-500 bg-opacity-50 font-bold" 
                                : (index % 2 === 0 ? "bg-blue-500 bg-opacity-30" : "bg-blue-400 bg-opacity-30")
                              }
                            >
                              <td className="py-2 px-2 text-xs 2xl:text-[1.5rem]">{row.year}</td>
                              <td className="py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] text-green-500">
                                ${isTotal ? row.Revenue.toLocaleString() : row.Revenue.toLocaleString()}
                              </td>
                              <td className="py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] text-red-500">
                                ${isTotal ? row.CapEx.toLocaleString() : row.CapEx.toLocaleString()}
                              </td>
                              <td className="py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] text-red-500">
                                ${isTotal ? row.OpEx.toLocaleString() : row.OpEx.toLocaleString()}
                              </td>
                              <td className={`py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] ${
                                profit >= 0 ? 'text-green-500' : 'text-red-500'
                              }`}>
                                ${(profit).toLocaleString()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  {/* Profit Calculation */}
                  <div className="mt-3 p-1 bg-blue-500 bg-opacity-30 rounded-lg">
                    <h4 className={`text-lg 2xl:text-[1.5rem] font-semibold ${
                      tableData.reduce((acc, row) => {
                        return acc + (convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx)));
                      }, 0) >= 0 ? 'text-green-500' : 'text-red-500'
                    }`}>
                      Net Profit = $
                      {tableData.reduce((acc, row) => {
                        return acc + (convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx)));
                      }, 0).toLocaleString()}
                    </h4>
                  </div>
                </div>

                {/* OpEx and Revenue Analysis Chart */}
                <div className="h-48 2xl:h-56 mb-5">
                  <div className="flex flex-row justify-between px-3 bg-blue-500 bg-opacity-20 rounded-lg pt-1 mb-2">
                    <h3 className="text-lg 2xl:text-[1.5rem] 2xl:leading-[2rem] font-semibold">OpEx Vs Revenue Analysis</h3>
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={opExAndRevenueData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="year" padding={{ left: 20, right: 20 }} />
                      <YAxis 
                        domain={getYAxisDomain()} 
                        tickCount={8} 
                        tickFormatter={formatYAxisValue}
                        width={100} 
                      />
                      <Tooltip 
                        formatter={(value) => [`$${value.toFixed(2)}`, '']} 
                        labelFormatter={(label) => `Year: ${label}`} 
                        contentStyle={{ color: '#000' }} 
                        itemStyle={{ color: '#000' }} 
                      />
                      <Legend />
                      <Line 
                        type="monotone" 
                        dataKey="OpEx" 
                        stroke="#82ca9d" 
                        name="Annual OpEx" 
                        strokeWidth={2} 
                        dot={{ r: 4 }} 
                        activeDot={{ r: 6 }} 
                      />
                      <Line 
                        type="monotone" 
                        dataKey="Revenue" 
                        stroke="#8884d8" 
                        name="Annual Revenue" 
                        strokeWidth={2} 
                        dot={{ r: 4 }} 
                        activeDot={{ r: 6 }} 
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* CapEx Chart */}
                <div className="h-48 2xl:h-56 mb-5">
                  <div className="bg-blue-500 bg-opacity-20 rounded-lg">
                    <div className="flex justify-between items-center px-3 py-1">
                      <h3 className="text-lg 2xl:text-[1.5rem]">Total CapEx: ${costs.totalCapEx.toLocaleString()}</h3>
                      <h3 className="text-lg font-semibold 2xl:text-[1.5rem]">CapEx Breakdown</h3>
                    </div>
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={capExData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={20}
                        outerRadius={50}
                        labelLine={true}
                        label={renderCustomizedLabel(capExData)}
                      >
                        {capExData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip formatter={(value) => `$${(value / 1000000).toFixed(1)}M`} />
                      <Legend verticalAlign="middle" align="right" layout="vertical" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                
                {/* Profit Analysis */}
                <div className="hidden 2xl:block">
                  <div className="h-48 2xl:h-56 mt-5">
                      <div className="flex flex-row justify-between px-3 bg-blue-500 bg-opacity-20 rounded-lg pt-1 mb-2">
                        <h3 className="text-lg 2xl:text-[1.5rem] 2xl:leading-[2rem] font-semibold">Annual Profit Analysis</h3>
                      </div>
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={coloProfit}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="year" padding={{ left: 20, right: 20 }} />
                          <YAxis 
                            domain={getYAxisDomain()}
                            tickCount={8} 
                            tickFormatter={formatYAxisValue}
                            width={100} 
                          />
                          <Tooltip 
                            formatter={(value) => [`$${value.toFixed(2)}`, '']}
                            labelFormatter={(label) => `Year: ${label}`} 
                            contentStyle={{ color: '#000' }} 
                            itemStyle={{ color: '#000' }} 
                          />
                          <Legend />
                          <Line 
                            type="monotone" 
                            dataKey="Profit" 
                            stroke="#ffa500" 
                            name="Annual Profit" 
                            strokeWidth={2} 
                            dot={{ r: 4 }} 
                            activeDot={{ r: 6 }} 
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                </div>
                  
              </div>
      
              {/* Hidden Charts (visible only when expanded) */}
              {isExpanded && (
                <div className="transition-opacity duration-1000 opacity-100">
                  <h2 className="text-xl 2xl:text-[1.5rem] 2xl:leading-[2rem] font-bold mb-2">Additional Analysis</h2>

                  {/* Power Distribution Chart */}
                  
                  <StackedInfrastructureChart 
                    powerData={powerData}
                    coolingData={coolingData}
                    totalRedundancyData={totalRedundancyData}
                  />
                  
                  
                  {/* OpEx Table */}
                  <div className="h-auto 2xl:h-auto mt-5 2xl:pt-8">
                    <div className="bg-blue-500 bg-opacity-20 rounded-lg mb-3">
                      <div className="flex justify-between items-center px-3 py-1">
                        <h3 className="text-lg 2xl:text-[1.5rem]">Monthly OpEx: ${costs.monthlyOpEx.toLocaleString()}</h3>
                        <h3 className="text-lg font-semibold 2xl:text-[1.5rem]">OpEx Breakdown</h3>
                      </div>
                    </div>

                    {/* Table */}
                    <div className="overflow-x-auto">
                      <table className="min-w-full rounded-lg text-left">
                        <thead>
                          <tr className="bg-blue-300 bg-opacity-30">
                            <th className="py-2 px-4 text-xs 2xl:text-[1.5rem]">Name</th>
                            <th className="py-2 px-4 text-xs 2xl:text-[1.5rem]">Value ($)</th>
                            <th className="py-2 px-4 text-xs 2xl:text-[1.5rem]">Percentage (%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {opExData.map((item, index) => {
                            const percentage = ((item.value / costs.monthlyOpEx) * 100).toFixed(2); // Calculate percentage
                            return (
                              <tr
                                key={index}
                                className={`${
                                  index % 2 === 0 ? "bg-blue-500 bg-opacity-30" : "bg-blue-400 bg-opacity-30"
                                }`}
                              >
                                <td className="py-2 px-4 text-xs 2xl:text-[1.5rem]">{item.name}</td>
                                <td className="py-2 px-4 text-xs 2xl:text-[1.5rem]">
                                  ${item.value.toLocaleString()}
                                </td>
                                <td className="py-2 px-4 text-xs 2xl:text-[1.5rem]">
                                  {percentage}%
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Profit Analysis */}
                  <div className="h-48 2xl:h-56 mt-5 2xl:hidden block">
                      <div className="flex flex-row justify-between px-3 bg-blue-500 bg-opacity-20 rounded-lg pt-1 mb-2">
                        <h3 className="text-lg 2xl:text-[1.5rem] 2xl:leading-[2rem] font-semibold">Annual Profit Analysis</h3>
                      </div>
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={coloProfit}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="year" padding={{ left: 20, right: 20 }} />
                          <YAxis 
                            domain={getYAxisDomain()}
                            tickCount={8} 
                            tickFormatter={formatYAxisValue}
                            width={100} 
                          />
                          <Tooltip 
                            formatter={(value) => [`$${value.toFixed(2)}`, '']}
                            labelFormatter={(label) => `Year: ${label}`} 
                            contentStyle={{ color: '#000' }} 
                            itemStyle={{ color: '#000' }} 
                          />
                          <Legend />
                          <Line 
                            type="monotone" 
                            dataKey="Profit" 
                            stroke="#ffa500" 
                            name="Annual Profit" 
                            strokeWidth={2} 
                            dot={{ r: 4 }} 
                            activeDot={{ r: 6 }} 
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>

                </div>
              )}
            </div>
          </div>
          )}
        </div>
      </div>
      {/* <Chatboat /> */}
    </div>
);

};

export default DataCenterCalculator;