import axiosInstance from '../config/axios';

export const postService = {
    createPost: async (formData) => {
        try {
            // Log request data
            console.log("Creating post with data:", {
                title: formData.get('title'),
                description: formData.get('description'),
                componentNames: formData.get('component_names'),
                hasImage: formData.has('image')
            });

            const response = await axiosInstance.post('/posts/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                // Add timeout and validation options
                timeout: 30000,
                validateStatus: (status) => {
                    return status >= 200 && status < 300;
                }
            });

            console.log("Post creation successful:", response.data);
            return response.data;

        } catch (error) {
            console.error("Error details:", {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });

            // Enhance error object with more details
            const enhancedError = new Error(error.message);
            enhancedError.response = error.response;
            enhancedError.data = error.response?.data;
            enhancedError.status = error.response?.status;

            throw enhancedError;
        }
    },

    getPosts: async (postType) => {
        try {
            const response = await axiosInstance.get('/posts/', {
                params: { post_type: postType }
            });
            return response.data;
        } catch (error) {
            console.error("Error in getPosts:", error);
            throw error;
        }
    },

    getPost: async (id) => {
        try {
            const response = await axiosInstance.get(`/posts/${id}/`);
            return response.data;
        } catch (error) {
            console.error("Error in getPost:", error);
            throw error;
        }
    },

    getPostBySlug: async (slug, postType = 'research') => {
        try {
            const response = await axiosInstance.get(`/posts/by-slug/${postType}/${slug}/`);
            return response.data;
        } catch (error) {
            console.error("Error in getPostBySlug:", error);
            throw error;
        }
    }
};