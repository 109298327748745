import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useLocation, useParams } from "react-router-dom";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const pdfjsDistVersion = "3.11.174";

function InsideResearch() {
  const { state } = useLocation();
  const { id } = useParams();
  const [research, setResearch] = useState(state?.research || null);
  const [loading, setLoading] = useState(!state?.research);

  useEffect(() => {
    // If we don't have research data from state, fetch it
    if (!research && id) {
      setLoading(true);
      fetch("/assets/research.json")
        .then((response) => response.json())
        .then((data) => {
          const foundResearch = data.find((item) => item.id.toString() === id);
          if (foundResearch) {
            setResearch(foundResearch);
          }
        })
        .catch((error) => {
          console.error("Error loading research data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, research]);

  if (loading) {
    return (
      <>
        <Navbar />
        <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-[#00237D] via-black to-[#00237D]">
          <div className="text-white text-xl">Loading...</div>
        </div>
      </>
    );
  }

  if (!research) {
    return (
      <>
        <Navbar />
        <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-[#00237D] via-black to-[#00237D]">
          <div className="text-white text-xl">Research not found.</div>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center py-20 bg-gradient-to-br from-[#00237D] via-black to-[#00237D] text-white">
        <h1 className="text-4xl 2xl:text-6xl 2xl:mt-12 font-bold mb-8 text-center px-6 sm:px-8">
          {research.title}
        </h1>
        
        <div className="max-w-5xl flex flex-col mb-6 px-6 sm:px-8 2xl:py-12">
          <div className="flex flex-col sm:flex-row items-start">
            <h2 className="text-lg 2xl:text-4xl font-semibold mb-2 sm:mb-0 sm:mr-2">
              Description:
            </h2>
            <p className="text-lg 2xl:text-3xl text-left">{research.description}</p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-4 px-6 sm:px-8">
          <h2 className="text-lg 2xl:text-4xl 2xl:mb-6 font-semibold">
            Components:
          </h2>
          <ul className="flex flex-wrap justify-center gap-2">
            {research.tags.map((tag, index) => (
              <li
                className="bg-blue-900/70 px-3 py-1 text-[0.9rem] 2xl:text-2xl uppercase tracking-wider text-white rounded-full dark:text-white/70"
                key={index}
              >
                {tag}
              </li>
            ))}
          </ul>
        </div>

        <div className="flex justify-between items-center w-full max-w-3xl lg:max-w-6xl mb-10 px-6 sm:px-8">
          <p className="text-gray-300 text-sm 2xl:text-2xl">
            Posted on: {new Date(research.date).toLocaleDateString()}
          </p>
          <a
            href={research.pdfLink}
            download={`${research.title}.pdf`}
            className="bg-gradient-to-br from-[#00237d99] to-[#00237d99] hover:bg-gradient-to-bl text-white 2xl:text-2xl font-bold py-2 px-4 rounded focus:ring focus:ring-blue-300 focus:outline-none"
          >
            Download PDF
          </a>
        </div>

        <div className="w-full max-w-3xl lg:max-w-6xl px-4">
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsDistVersion}/build/pdf.worker.min.js`}>
            <Viewer fileUrl={research.pdfLink} />
          </Worker>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InsideResearch;