import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip,LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';


const ColoII = () => {
    const [isExpanded, setIsExpanded] = useState(false);
  const [formData, setFormData] = useState({
    //colo 2 variables

    colo2rackAvailability: 100,
    colo2powerRates: 10,
    colo2rackSpaceRentalRates: 10,
    optionalAddOn: 'Cross_connects',
    colo2buildingOwnership: 'owned',
    colo2leaseRate: 300,
    colo2powerCapacity: 1000,
    colo2coreShellCost: 50,
    colo2coolingApproach: 'air',
    colo2rackCount: 10,
    colo2gpuServersPerRack: 8,
    colo2powerDensity: 1,
    colo2upsContract: false,
    colo2expansion: true,
    colo2expansionTimeline: '1_2years',
    colo2environmentalGoals: true,
    colo2pueTarget: 1.2,
    colo2airDistributionType: 'hotColdContainment',
    colo2upsStyle: 'onlineDoubleConversion',
    colo2powerDistributionType: 'basicPanelboards',
    colo2crahCracRedundancy: 'N', 
    colo2pumpsRedundancy: 'N', 
    colo2chillerRedundancy: 'N', 
    colo2heatRejectionRedundancy: 'N',
    colo2itDistributionRedundancy: 'N',
    colo2upsRedundancy: 'N',
    colo2generatorRedundancy: 'N',
    colo2coloRate: 163, // Changed from colo2powerRates, default to average market rate
  });

  // Enhanced cost calculation constants
  const COST_CONSTANTS = {
    SHELL_COST_PER_SQFT: 250,
    lease_rate_sqft_month: 2.00,
    COOLING_COST_BASE: {
      air: 400,    // $/kW
      liquid: 600, // $/kW
      hybrid: 500  // $/kW
    },
    POWER_DIST_BASE: 400, // $/kW
    UPS_BASE: 200,       // $/kW
    GEN_COST_PER_KW: 300,
    RACK_COST: {
      cpu: 100000,  // $ per rack
      gpu: 300000,
      both: 200000 // $ per rack
    },
    NETWORK_BASE_PER_RACK: 10000,
    infiniBand_multiplier: 2.5,
    POWER_RATE: 0.12,    // $/kWh
    PUE: {
      air: 1.4,
      liquid: 1.2,
      hybrid: 1.3
    },
    redundancy_factor_map: {
      'N': 1.00,
      'N+1': 1.20,
      '2N': 2.00,
      '2(N+1)': 2.20,
    },
    cost_per_kW_ITDist: 200,  
    cost_per_kW_UPS: 200,
    cost_per_kW_Gen: 300,
    cost_per_kW_CRAH: 300,
    cost_per_kW_Pumps: 80,
    cost_per_kW_Chiller: 400,
    cost_per_kW_HeatRejection: 100,
    MAINTENANCE_RATE: 0.01, // 1% of power capacity as monthly maintenance cost
  };

  const [colo2Costs, setColo2Costs] = useState({
    colo2TotalCapEx: 0,
    colo2MonthlyOpEx: 0,
    colo2PowerCost: 0,
    totalYearlyRevenue: 0,
    otherCost: 0,

    colo2coreShellCost: 0,
    colo2powerCoolingCost: 0,
    colo2otherCost: 0,

    monthly_lease_cost: 0,


    colo2final_power_capex: 0,
    colo2final_cooling_capex: 0,
    colo2final_ITDist: 0,
    colo2final_UPS: 0,
    colo2final_Gen: 0,
    colo2final_CRAH: 0,
    colo2final_Pumps: 0,
    colo2final_Chiller: 0,
    colo2final_HeatRej: 0,
  });

  const calculateEnhancedCosts = () => {
    // ---------------------------
    // 1) Subsystem Redundancy Costs
    // ---------------------------
    const base_ITDist = formData.colo2powerCapacity * COST_CONSTANTS.cost_per_kW_ITDist;
    const base_UPS = formData.colo2powerCapacity * COST_CONSTANTS.cost_per_kW_UPS;
    const base_Gen = formData.colo2powerCapacity * COST_CONSTANTS.cost_per_kW_Gen;
    const base_CRAH = formData.colo2powerCapacity * COST_CONSTANTS.cost_per_kW_CRAH;
    const base_Pumps = formData.colo2powerCapacity * COST_CONSTANTS.cost_per_kW_Pumps;
    const base_Chiller = formData.colo2powerCapacity * COST_CONSTANTS.cost_per_kW_Chiller;
    const base_HeatRej = formData.colo2powerCapacity * COST_CONSTANTS.cost_per_kW_HeatRejection;

    // Apply redundancy factors
    const colo2final_ITDist = base_ITDist * COST_CONSTANTS.redundancy_factor_map[formData.colo2itDistributionRedundancy];
    const colo2final_UPS = base_UPS * COST_CONSTANTS.redundancy_factor_map[formData.colo2upsRedundancy];
    const colo2final_Gen = base_Gen * COST_CONSTANTS.redundancy_factor_map[formData.colo2generatorRedundancy];
    const colo2final_CRAH = base_CRAH * COST_CONSTANTS.redundancy_factor_map[formData.colo2crahCracRedundancy];
    const colo2final_Pumps = base_Pumps * COST_CONSTANTS.redundancy_factor_map[formData.colo2pumpsRedundancy];
    const colo2final_Chiller = base_Chiller * COST_CONSTANTS.redundancy_factor_map[formData.colo2chillerRedundancy];
    const colo2final_HeatRej = base_HeatRej * COST_CONSTANTS.redundancy_factor_map[formData.colo2heatRejectionRedundancy];

    const colo2final_power_capex = colo2final_ITDist + colo2final_UPS + colo2final_Gen;
    const colo2final_cooling_capex = colo2final_CRAH + colo2final_Pumps + colo2final_Chiller + colo2final_HeatRej;

    // ---------------------------
    // 2) Building & Core Systems
    // ---------------------------
    // Cooling base calculation
    const coolingBase = COST_CONSTANTS.COOLING_COST_BASE[formData.colo2coolingApproach];
    const coolingCost = formData.colo2powerCapacity * coolingBase * (formData.colo2airDistributionType === 'hotColdContainment' ? 1.2 : 1.0);

    // Power distribution
    const pdMultiplier = formData.colo2powerDistributionType === 'busway' ? 1.1 : 1.0;
    const powerDistCost = formData.colo2powerCapacity * COST_CONSTANTS.POWER_DIST_BASE * pdMultiplier;

    // UPS calculation
    const upsMultiplier = formData.colo2upsStyle === 'onlineDoubleConversion' ? 1.1 : 0.9;
    const upsCost = formData.colo2powerCapacity * COST_CONSTANTS.UPS_BASE * upsMultiplier;

    // Generator base cost
    const genCost = formData.colo2powerCapacity * COST_CONSTANTS.GEN_COST_PER_KW;

    // ---------------------------
    // 3) Rack Setup Costs
    // ---------------------------
    const rackSetupCost = formData.colo2rackCount * COST_CONSTANTS.RACK_COST.gpu;

    // ---------------------------
    // 4) Revenue Calculations
    // ---------------------------
    // Colo revenue (now using monthly rate directly)
    const monthlyColoRevenue = formData.colo2powerCapacity * formData.colo2coloRate;
    
    // Rack rental revenue
    const monthlyRackRevenue = formData.colo2rackCount * formData.colo2rackSpaceRentalRates;
    
    // Total monthly revenue
    const totalMonthlyRevenue = monthlyColoRevenue + monthlyRackRevenue;
    const totalYearlyRevenue = totalMonthlyRevenue * 12;

    // ---------------------------
    // 5) Total CapEx
    // ---------------------------
    const totalCapEx = (
      colo2final_power_capex +  
      colo2final_cooling_capex +
      coolingCost +
      powerDistCost +
      upsCost +
      genCost +
      rackSetupCost +
      (formData.colo2buildingOwnership === 'owned' ? formData.colo2coreShellCost * COST_CONSTANTS.SHELL_COST_PER_SQFT : 0)
    );

    // ---------------------------
    // 6) OpEx Calculations
    // ---------------------------
    // Power costs
    const monthlyKWh = formData.colo2powerCapacity * formData.colo2pueTarget * 720;
    const monthlyPowerCost = monthlyKWh * COST_CONSTANTS.POWER_RATE;

    // Maintenance costs
    const maintenanceCost = formData.colo2upsContract ? 
      (formData.colo2powerCapacity * COST_CONSTANTS.MAINTENANCE_RATE || 0.01) : 0;

    // Lease costs
    const monthly_lease_cost = formData.colo2buildingOwnership === 'leased' ? 
      formData.colo2coreShellCost * COST_CONSTANTS.lease_rate_sqft_month * formData.colo2leaseRate : 0;

    // Total monthly OpEx
    const monthlyOpEx = monthlyPowerCost + monthly_lease_cost + maintenanceCost;

    // Network costs calculation
    const networkingCost = formData.colo2rackCount * COST_CONSTANTS.NETWORK_BASE_PER_RACK;

    // Core & Shell cost (only if owned)
    const coreShellCost = formData.colo2buildingOwnership === 'owned' ? 
      formData.colo2coreShellCost * COST_CONSTANTS.SHELL_COST_PER_SQFT : 0;

    // Update costs state with all components
    setColo2Costs({
      colo2TotalCapEx: totalCapEx,
      colo2MonthlyOpEx: monthlyOpEx,
      colo2PowerCost: monthlyPowerCost,
      totalYearlyRevenue,
      otherCost: coolingCost + powerDistCost + upsCost + genCost,
      
      // For CapEx breakdown - now including networking and core/shell
      colo2coreShellCost: coreShellCost,
      colo2powerCoolingCost: colo2final_power_capex + colo2final_cooling_capex,
      colo2rackSetupCost: rackSetupCost,
      colo2networkingCost: networkingCost,  // Added networking cost
      //colo2otherCost: coolingCost + powerDistCost + upsCost + genCost,
      
      // For OpEx breakdown
      monthly_lease_cost,
      maintenanceCost,

      // For Redundancy breakdown
      colo2final_power_capex,
      colo2final_cooling_capex,
      colo2final_ITDist,
      colo2final_UPS,
      colo2final_Gen,
      colo2final_CRAH,
      colo2final_Pumps,
      colo2final_Chiller,
      colo2final_HeatRej,
    });
    console.log(totalCapEx);
    console.log(monthlyOpEx);
  };

  useEffect(calculateEnhancedCosts, [formData]);

    const handleITDistributionChange = (value) => {
        const newFormData = {
        ...formData,
        colo2itDistributionRedundancy: value,
        colo2upsRedundancy: 'N',
        colo2generatorRedundancy: 'N'
        };
        setFormData(newFormData);
    };
    const handleUPSChange = (value) => {
        let generatorValue = formData.colo2generatorRedundancy;
        
        // Ensure generator redundancy is compatible with UPS redundancy
        if (value === 'N') generatorValue = 'N';
        else if (value === 'N+1') generatorValue = 'N+1';
        else if (value === '2N') generatorValue = '2N';
        else if (value === '2(N+1)') generatorValue = '2(N+1)';
        
        setFormData({
        ...formData,
        colo2upsRedundancy: value,
        colo2generatorRedundancy: generatorValue
        });
    };
    const handleCRAHChange = (value) => {
        let pumpsValue = formData.colo2pumpsRedundancy;
        let chillerValue = formData.colo2chillerRedundancy;
        let heatRejectionValue = formData.colo2heatRejectionRedundancy;
        
        // Set default values based on CRAH/CRAC selection
        if (value === 'N') {
        pumpsValue = 'N';
        chillerValue = 'N';
        heatRejectionValue = 'N';
        } else if (value === '2N') {
        pumpsValue = '2N';
        chillerValue = '2N';
        heatRejectionValue = '2N';
        } else if (value === 'N+1') {
        pumpsValue = 'N';
        chillerValue = 'N';
        heatRejectionValue = 'N';
        }
        
        setFormData({
        ...formData,
        colo2crahCracRedundancy: value,
        colo2pumpsRedundancy: pumpsValue,
        colo2chillerRedundancy: chillerValue,
        colo2heatRejectionRedundancy: heatRejectionValue
        });
    };
    const renderColo2 = () => (
        <div className="space-y-2 text-xs">
          {/* Section 3.A: Site & Facilities */}
          <section>
            <div className='bg-blue-500 bg-opacity-20 p-1 rounded-lg'>
              <h3 className="text-sm 2xl:text-[1.7rem] font-bold mb-1 2xl:p-2">A: Site & Facilities</h3>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center text-xs"> {/* Text size adjusted */}
              <div className="flex items-center">
                <label className="text-xs 2xl:text-[1.5rem] w-1/3">Building Ownership</label>
                <div className="flex gap-1 w-2/3"> {/* Adjusted gap */}
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-xs"
                      value="owned"
                      checked={formData.colo2buildingOwnership === 'owned'}
                      onChange={() =>
                        setFormData({ ...formData, colo2buildingOwnership: 'owned' })
                      }
                    />
                    <span className="ml-1 2xl:ml-2 text-xs 2xl:text-[1.5rem]">Owned</span>
                  </label>
                  <label className="inline-flex items-center 2xl:ml-2">
                    <input
                      type="radio"
                      className="form-radio text-xs"
                      value="leased"
                      checked={formData.colo2buildingOwnership === 'leased'}
                      onChange={() =>
                        setFormData({ ...formData, colo2buildingOwnership: 'leased' })
                      }
                    />
                    <span className="ml-1 2xl:ml-2 text-xs 2xl:text-[1.5rem]">Leased</span>
                  </label>
                </div>
              </div>
              {formData.colo2buildingOwnership === 'leased' && (
                <div className="flex items-center mt-2">
                  <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">Lease Rate</label>
                  <input
                    type="number"
                    className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10 "
                    value={formData.colo2leaseRate}
                    onChange={(e) =>
                      setFormData({ ...formData, colo2leaseRate: e.target.value })
                    }
                  />
                </div>
              )}
              {formData.colo2buildingOwnership === 'owned' && (
                <div className="2xl:mt-1">
                  <label className="block mb-2 2xl:mt-2">
                    <span className="text-xs 2xl:text-[1.5rem]">Core & Shell (ft²):</span>
                    <input
                      type="range"
                      min="0"
                      max="300"
                      step="10"
                      value={formData.colo2coreShellCost}
                      onChange={(e) =>
                        setFormData({ ...formData, colo2coreShellCost: Number(e.target.value) })
                      }
                      className="mt-1 block w-full text-xs 2xl:text-[1.5rem]"
                    />
                    <div className="text-xs 2xl:text-[1.5rem]">{formData.colo2coreShellCost} ft²</div>
                  </label>
                </div>
              )}
              <div>
                <label className="block mb-2">
                  <span className="text-xs 2xl:text-[1.5rem]">Power Needed (MW):</span>
                  <div className="flex items-center gap-2">
                    {/* Range Slider */}
                    <input
                      type="range"
                      min="0.1"
                      max="1000"
                      step="0.1"
                      value={formData.colo2powerCapacity / 1000}
                      onChange={(e) => {
                        const powerCapacity = Number(e.target.value) * 1000;
                        const rackCount = Math.ceil(powerCapacity / formData.colo2powerDensity);

                        // Update formData
                        setFormData({
                          ...formData,
                          colo2powerCapacity: powerCapacity,
                          rackCount, // Store rack count directly
                        });
                      }}
                      className="mt-1 flex-1"
                    />

                    {/* Input Field */}
                    <input
                      type="number"
                      min="0.1"
                      max="1000"
                      step="0.1"
                      value={formData.colo2powerCapacity / 1000}
                      onChange={(e) => {
                        const powerCapacity = Number(e.target.value) * 1000;
                        if (powerCapacity >= 100 && powerCapacity <= 1000000) {
                          const rackCount = Math.ceil(powerCapacity / formData.colo2powerDensity);

                          // Update formData
                          setFormData({
                            ...formData,
                            colo2powerCapacity: powerCapacity,
                            rackCount, // Store rack count directly
                          });
                        }
                      }}
                      className="w-24 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                    />
                  </div>
                  <div className="text-xs 2xl:text-[1.5rem] mt-1">
                    {(formData.colo2powerCapacity / 1000).toLocaleString()} MW
                  </div>
                </label>
              </div>

    
              <div className="flex items-center">
                <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">Cooling Approach</label>
                <select
                  className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10 2xl:bg-gray-500 2xl:bg-opacity-10"
                  value={formData.colo2coolingApproach}
                  onChange={(e) =>
                    setFormData({ ...formData, colo2coolingApproach: e.target.value })
                  }
                >
                  <option value="air" className="bg-gray-500 ">Air-cooled</option>
                  <option value="liquid" className="bg-gray-500 ">Liquid-cooled</option>
                  <option value="hybrid" className="bg-gray-500">Hybrid</option>
                </select>
              </div>
    
    
              <div className="flex items-center 2xl:mt-1">
                <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">Air Distribution Type</label>
                <select
                  className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                  value={formData.colo2airDistributionType}
                  onChange={(e) =>
                    setFormData({ ...formData, colo2airDistributionType: e.target.value })
                  }
                >
                  <option value="hotColdContainment" className="bg-gray-500">Hot-Aisle/Cold-Aisle Containment</option>
                  <option value="rowBased" className="bg-gray-500">Row-Based Cooling</option>
                  <option value="perimeter" className="bg-gray-500">Perimeter Cooling</option>
                  <option value="other" className="bg-gray-500">Other / Custom</option>
                </select>
              </div>
    
              <div className="flex items-center">
                <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">UPS Style</label>
                <select
                  className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                  value={formData.colo2upsStyle}
                  onChange={(e) =>
                    setFormData({ ...formData, colo2upsStyle: e.target.value })
                  }
                >
                  <option value="onlineDoubleConversion" className="bg-gray-500">Online Double-Conversion</option>
                  <option value="lineInteractive" className="bg-gray-500">Line-Interactive</option>
                  <option value="offline" className="bg-gray-500">Offline/Standby</option>
                  <option value="other" className="bg-gray-500">Other</option>
                </select>
              </div>
    
              <div className="flex items-center 2xl:mt-1 2xl:mb-1">
                <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[1.5rem] font-bold w-1/3 pr-1">Power Distribution Type</label>
                <select
                  className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                  value={formData.colo2powerDistributionType}
                  onChange={(e) =>
                    setFormData({ ...formData, colo2powerDistributionType: e.target.value })
                  }
                >
                  <option value="basicPanelboards" className="bg-gray-500">Basic Wall-Mount Panelboards</option>
                  <option value="busway" className="bg-gray-500">Busway / Overhead Distribution</option>
                  <option value="floorPDU" className="bg-gray-500">Floor PDU boxes</option>
                  <option value="other" className="bg-gray-500">Other</option>
                </select>
              </div>
            </div>
          </section>
    
          {/* Section 3.B: colo offerings*/}
          <section className="2xl:pt-2">
            <div className='bg-blue-500 bg-opacity-20 p-1 rounded-lg'>
              <h3 className="text-sm 2xl:text-[1.7rem] font-bold mb-1 2xl:p-2">B: Colo Offerings</h3>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center text-xs"> {/* Text size adjusted */}

              <div className="2xl:mt-1">
                <label className="block mb-2 2xl:mt-2">
                  <span className="text-xs 2xl:text-[1.5rem]">Rack Availability:</span>
                  <input
                    type="range"
                    min="0"
                    max="300"
                    step="10"
                    value={formData.colo2rackAvailability}
                    onChange={(e) =>
                      setFormData({ ...formData, colo2rackAvailability: Number(e.target.value) })
                    }
                    className="mt-1 block w-full text-xs 2xl:text-[1.5rem]"
                  />
                  <div className="text-xs 2xl:text-[1.5rem]">{formData.colo2rackAvailability}</div>
                </label>
              </div>
              
              <div>
                <label className="block mb-2">
                  <span className="text-xs 2xl:text-[1.5rem]">Colo Rate ($/kW/month):</span>
                  <div className="flex items-center gap-2">
                    {/* Range Slider */}
                    <input
                      type="range"
                      min="10"
                      max="300"
                      step="1"
                      value={formData.colo2coloRate}
                      onChange={(e) => {
                        const powerRates = Number(e.target.value);

                        // Update formData
                        setFormData({
                          ...formData,
                          colo2coloRate: powerRates,
                        });
                      }}
                      className="mt-1 flex-1"
                    />

                    {/* Input Field */}
                    <input
                      type="number"
                      min="10"
                      max="300"
                      step="1"
                      value={formData.colo2coloRate}
                      onChange={(e) => {
                        const powerRates = Number(e.target.value);

                        // Ensure value stays within range
                        if (powerRates >= 10 && powerRates <= 300) {
                          setFormData({
                            ...formData,
                            colo2coloRate: powerRates,
                          });
                        }
                      }}
                      className="w-24 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                    />
                  </div>
                  <div className="text-xs 2xl:text-[1.5rem] mt-1">
                    {formData.colo2coloRate} $/kW/month
                  </div>
                </label>
              </div>



              <div className="block">
                <span className="2xl:text-[1.5rem] 2xl:leading-[1.5rem]">Rack Space Rental Rates:</span>
                <input
                  type="number"
                  min="1"
                  value={formData.colo2rackSpaceRentalRates}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      colo2rackSpaceRentalRates: Number(e.target.value),
                    })
                  }
                  className="w-16 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10 ml-4"
                />
              </div>

              <div>
                <label className="block 2xl:text-[1.5rem] 2xl:leading-[2rem]">Optional Add-ons:</label>
                {['Cross-connects', 'remote hands', 'monitoring'].map(
                  (optionalAddOn) => (
                    <label key={optionalAddOn} className="inline-flex items-center mr-2">
                      <input
                        type="checkbox"
                        className="form-checkbox text-xs 2xl:text-[1.5rem]"
                        checked={
                          (optionalAddOn === 'Cross-connects' && formData.optionalAddOn === 'Cross_connects') ||
                          (optionalAddOn === 'remote hands' && formData.optionalAddOn === 'remote_hands') ||
                          (optionalAddOn === 'monitoring' && formData.optionalAddOn === 'monitoring')
                        }
                        onChange={() => {
                          let serverTypeValue;
                          if (optionalAddOn === 'Cross-connects') serverTypeValue = 'Cross_connects';
                          else if (optionalAddOn === 'remote hands') serverTypeValue = 'remote_hands';
                          else if (optionalAddOn === 'monitoring') serverTypeValue = 'monitoring';

                          setFormData({ ...formData, optionalAddOn: serverTypeValue });
                        }}
                      />
                      <span className="ml-1 text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">{optionalAddOn}</span>
                    </label>
                  )
                )}
              </div>

              <div>
                <label className="block 2xl:text-[1.5rem] 2xl:leading-[2rem]">UPS & Generator Maintenance:</label>
                <div className="flex items-center 2xl:mb-2">
                  <input
                    type="checkbox"
                    className="form-checkbox text-xs 2xl:text-[1.5rem]"
                    checked={formData.colo2upsContract}
                    onChange={() =>
                      setFormData({ ...formData, colo2upsContract: !formData.colo2upsContract })
                    }
                  />
                  <span className="ml-1 text-xs 2xl:text-[1.5rem]">I have a maintenance contract in place</span>
                </div>
              </div>
            </div>
          </section>
    
          <section>
          <div className="">
          <div className="bg-blue-500 bg-opacity-20 p-1 rounded-lg">
            <h3 className="text-sm 2xl:text-[1.5rem] 2xl:leading-[2rem] font-bold mb-1">C: Redundancy Levels</h3>
          </div>
          
          <div className="space-y-4">
            {/* Power Redundancy Section */}
            <div className="flex items-center space-x-4">
              <label className="block w-32 2xl:text-[1.5rem] 2xl:leading-[2rem]">Power Redundancy</label>
              
              <div className="flex flex-col items-center">
                <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">IT Distribution</label>
                <select
                  className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10" 
                  value={formData.colo2itDistributionRedundancy}
                  onChange={(e) => handleITDistributionChange(e.target.value)}
                >
                  <option value="N" className="bg-gray-500">N</option>
                  <option value="2N" className="bg-gray-500">2N</option>
                </select>
              </div>
    
              <div className="flex flex-col items-center">
                <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">UPS</label>
                <select
                  className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
                  value={formData.colo2upsRedundancy}
                  onChange={(e) => handleUPSChange(e.target.value)}
                >
                  {formData.colo2itDistributionRedundancy === 'N' ? (
                    <>
                      <option value="N" className="bg-gray-500">N</option>
                      <option value="N+1" className="bg-gray-500">N+1</option>
                    </>
                  ) : (
                    <>
                      <option value="N" className="bg-gray-500">N</option>
                      <option value="N+1" className="bg-gray-500">N+1</option>
                      <option value="2N" className="bg-gray-500">2N</option>
                      <option value="2(N+1)" className="bg-gray-500">2(N+1)</option>
                    </>
                  )}
                </select>
              </div>
    
              <div className="flex flex-col items-center">
                <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">Generator</label>
                <select
                  className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
                  value={formData.colo2generatorRedundancy}
                  onChange={(e) => setFormData({ ...formData, colo2generatorRedundancy: e.target.value })}
                >
                  {formData.colo2upsRedundancy === 'N' && <option value="N" className="bg-gray-500">N</option>}
                  {formData.colo2upsRedundancy === 'N+1' && (
                    <>
                      <option value="N" className="bg-gray-500">N</option>
                      <option value="N+1" className="bg-gray-500">N+1</option>
                    </>
                  )}
                  {formData.colo2upsRedundancy === '2N' && (
                    <>
                      <option value="N+1" className="bg-gray-500">N+1</option>
                      <option value="2N" className="bg-gray-500">2N</option>
                    </>
                  )}
                  {formData.colo2upsRedundancy === '2(N+1)' && (
                    <>
                      <option value="N+1" className="bg-gray-500">N+1</option>
                      <option value="2N" className="bg-gray-500">2N</option>
                      <option value="2(N+1)" className="bg-gray-500">2(N+1)</option>
                    </>
                  )}
                </select>
              </div>
            </div>
    
            {/* Cooling Redundancy Section */}
            <div className="flex items-center space-x-4">
              <label className="block w-32 2xl:text-[1.5rem] 2xl:leading-[2rem]">Cooling Redundancy</label>
              
              <div className="flex flex-col items-center">
                <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">CRAH/CRAC</label>
                <select
                  className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
                  value={formData.colo2crahCracRedundancy}
                  onChange={(e) => handleCRAHChange(e.target.value)}
                >
                  <option value="N" className="bg-gray-500">N</option>
                  <option value="N+1" className="bg-gray-500">N+1</option>
                  <option value="2N" className="bg-gray-500">2N</option>
                </select>
              </div>
    
              <div className="flex flex-col items-center">
                <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">Pumps</label>
                <select
                  className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
                  value={formData.colo2pumpsRedundancy}
                  onChange={(e) => setFormData({ ...formData, colo2pumpsRedundancy: e.target.value })}
                >
                  {formData.colo2crahCracRedundancy === '2N' ? (
                    <>
                      <option value="2N" className="bg-gray-500">2N</option>
                      <option value="2(N+1)" className="bg-gray-500">2(N+1)</option>
                    </>
                  ) : (
                    <>
                      <option value="N" className="bg-gray-500">N</option>
                      <option value="N+1" className="bg-gray-500">N+1</option>
                    </>
                  )}
                </select>
              </div>
    
              <div className="flex flex-col items-center">
                <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">Chiller</label>
                <select
                  className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
                  value={formData.colo2chillerRedundancy}
                  onChange={(e) => setFormData({ ...formData, colo2chillerRedundancy: e.target.value })}
                >
                  {formData.colo2crahCracRedundancy === '2N' ? (
                    <>
                      <option value="2N" className="bg-gray-500">2N</option>
                      <option value="2(N+1)" className="bg-gray-500">2(N+1)</option>
                    </>
                  ) : (
                    <>
                      <option value="N" className="bg-gray-500">N</option>
                      <option value="N+1" className="bg-gray-500">N+1</option>
                    </>
                  )}
                </select>
              </div>
    
              <div className="flex flex-col items-center">
                <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">Heat Rejection</label>
                <select
                  className="w-20 text-center border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
                  value={formData.colo2heatRejectionRedundancy}
                  onChange={(e) => setFormData({ ...formData, colo2heatRejectionRedundancy: e.target.value })}
                >
                  {formData.colo2crahCracRedundancy === '2N' ? (
                    <>
                      <option value="2N" className="bg-gray-500">2N</option>
                      <option value="2(N+1)" className="bg-gray-500">2(N+1)</option>
                    </>
                  ) : (
                    <>
                      <option value="N" className="bg-gray-500">N</option>
                      <option value="N+1" className="bg-gray-500">N+1</option>
                    </>
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
          </section>
    
          {/* Section 5: Additional Details */}
          <section>
            <div className='bg-blue-500 bg-opacity-20 p-1 rounded-lg'>
              <h3 className="text-sm 2xl:text-[1.5rem] 2xl:leading-[2rem] font-bold mb-1">D: Additional Considerations</h3>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center text-xs"> {/* Text size adjusted */}
              <div>
                <label className="block 2xl:text-[1.5rem] 2xl:leading-[2rem]">Future Expansion Planned?</label>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox text-xs 2xl:text-[1.5rem]"
                    checked={formData.colo2expansion}
                    onChange={() =>
                      setFormData({ ...formData, colo2expansion: !formData.colo2expansion })
                    }
                  />
                  <span className="ml-1 text-xs 2xl:text-[1.5rem]">Yes</span>
                </div>
                {formData.colo2expansion && (
                  <div className="mt-2">
                    <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Approximate Timeline</label>
                    <select
                      className="w-full p-1 text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] border rounded bg-gray-500 bg-opacity-10"
                      value={formData.colo2expansionTimeline}
                      onChange={(e) =>
                        setFormData({ ...formData, colo2expansionTimeline: e.target.value })
                      }
                    >
                      <option value="1_2years" className="bg-gray-500">1–2 years</option>
                      <option value="3_5years" className="bg-gray-500">3–5 years</option>
                      <option value="5_plusyears" className="bg-gray-500">5+ years</option>
                    </select>
                  </div>
                )}
              </div>
              <div>
                <label className="block 2xl:text-[1.5rem] 2xl:leading-[2rem]">Environmental / Efficiency Goals?</label>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox text-xs 2xl:text-[1.5rem]"
                    checked={formData.colo2environmentalGoals}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        colo2environmentalGoals: !formData.colo2environmentalGoals,
                      })
                    }
                  />
                  <span className="ml-1 text-xs 2xl:text-[1.5rem]">Yes</span>
                </div>
                {formData.colo2environmentalGoals && (
                  <div className="mt-2">
                    <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Target PUE or Renewable Energy Goal</label>
                    <input
                      type="text"
                      className="w-full p-1 border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
                      placeholder="Enter target"
                      value={formData.colo2pueTarget}
                      onChange={(e) =>
                        setFormData({ ...formData, colo2pueTarget: e.target.value })
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
    );


    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

    const coloCapExData = [
      { name: 'Core & Shell', value: colo2Costs.colo2coreShellCost },
      { name: 'Power & Cooling', value: colo2Costs.colo2powerCoolingCost },
      { name: 'Rack Setup', value: colo2Costs.colo2rackSetupCost },
      { name: 'Networking', value: colo2Costs.colo2networkingCost },
      { name: 'Other Infrastructure', value: colo2Costs.otherCost }
    ].filter(item => item.value > 0);  // Only show components with non-zero values

    const coloOpExData = [
      { name: 'ColoPower', value: colo2Costs.colo2PowerCost },
      { name: 'Monthly Lease', value: colo2Costs.monthly_lease_cost },
      { name: 'Other', value: colo2Costs.colo2otherCost }
    ];
  
    // Calculate Colo OpEx and Revenue Data for Analysis
    const calculateColoOpExAndRevenueData = () => {
      const baseYear = 2025;
      let years = 0;
      const expansionTimeline = formData.colo2expansionTimeline;

      if (expansionTimeline === '1_2years') {
        years = 3;
      } else if (expansionTimeline === '3_5years') {
        years = 5;
      } else if (expansionTimeline === '5_plusyears') {
        years = 7;
      }

      const annualOpExGrowth = 1.07; // 7% annual increase
      const annualRevenueGrowth = 1.07; // 7% annual increase


      return Array.from({ length: years }, (_, index) => {
        const year = baseYear + index;
        const yearlyOpEx = colo2Costs.colo2MonthlyOpEx * 12 * Math.pow(annualOpExGrowth, index);
        const yearlyTotalRevenue = colo2Costs.totalYearlyRevenue * Math.pow(annualRevenueGrowth, index);

        return {
          year: year.toString(),
          OpEx: yearlyOpEx,
          Revenue: yearlyTotalRevenue,
        };
      });
    };

    const coloOpExAndRevenueData = calculateColoOpExAndRevenueData();
      
    // Calculate CapEx Data
    const calculateColoCapExData = () => {
      const baseYear = 2025;
      let years = 0;
      const expansionTimeline = formData.colo2expansionTimeline;

      if (expansionTimeline === '1_2years') {
        years = 3;
      } else if (expansionTimeline === '3_5years') {
        years = 5;
      } else if (expansionTimeline === '5_plusyears') {
        years = 7;
      }

      return Array.from({ length: years }, (_, index) => {
        const year = baseYear + index;
        return {
          year: year.toString(),
          CapEx: index === 0 ? colo2Costs.colo2TotalCapEx.toLocaleString() : 0 
        };
      });
    };

    // Merge Revenue, OpEx, and CapEx Data
  const generateColoTableData = () => {
    const capExData = calculateColoCapExData();
    return coloOpExAndRevenueData.map((item, index) => ({
      year: item.year,
      Revenue: item.Revenue,
      OpEx: item.OpEx,
      CapEx: capExData[index]?.CapEx || 0, // Use CapEx for the corresponding year
    }));
  };

  const coloTableData = generateColoTableData();

  // Calculate domain for y-axis
  const getYAxisDomain = () => {
    const opExValues = coloOpExAndRevenueData.flatMap(item => [item.OpEx, item.Revenue]);
    const minValue = Math.min(...opExValues);
    const maxValue = Math.max(...opExValues);
    const padding = (maxValue - minValue) * 0.1; // Add 10% padding

    return [minValue - padding, maxValue + padding];
  };
  // Function to format numbers with appropriate suffixes
  const formatYAxisValue = (value) => {
    if (Math.abs(value) >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (Math.abs(value) >= 1000) {
      return `$${(value / 1000).toFixed(1)}K`;
    }
    return `$${value.toFixed(0)}`;
  };

  function convertToInteger(value) {
    // Check if the value is already an integer
    if (typeof value === 'number') {
      return value; // Return as-is if it's already an integer
    }
  
    // If it's a string, process it
    if (typeof value === 'string') {
      // Check if there are commas
      if (value.includes(',')) {
        return parseInt(value.replace(/,/g, ''), 10); // Remove commas and convert
      } else {
        return parseInt(value, 10); // Convert directly if no commas
      }
    }
  
    // If the input is not a string or number, return NaN
    return NaN;
  }
  
  // Calculate Profit Data
const calculateProfitData = () => {
  return coloTableData.map((row, index) => {
    return {
      year: row.year,
      Profit: convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx))
    };
  });
};

const coloProfit = calculateProfitData();

  // Custom label for the donut charts
  const renderCustomizedLabel = (data) => ({ cx, cy, midAngle, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 30; // Position the label further outside the pie
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    let y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    // Avoid collision by adjusting y position if labels overlap
    if (index > 0 && y === previousLabelY) {
      y += window.innerWidth >= 2560 ? 40 : 15; 
    }
    previousLabelY = y; // Update the last label position for comparison
  
    return (
      <text
        x={x}
        y={y}
        fill="#f3f4f6"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={window.innerWidth >= 2560 ? '1.5rem' : '12px'}
      >
        {`${data[index].name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };
  let previousLabelY = null;
  
    return (
      <div className="relative flex flex-row items-stretch gap-3">
        {/* Background Overlay */}
        {isExpanded && (
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
            style={{ zIndex: 40 }}
            onClick={() => setIsExpanded(false)}
          ></div>
        )}
        {/* Main Content */}
        <div className="w-[60%] bg-blue-500 bg-opacity-30 rounded-lg shadow-lg p-6">
          {renderColo2()}
        </div>
  
        {/* Results Container */}
        <div 
          className={`absolute transition-all duration-500 ease-in-out rounded-lg shadow-lg p-6 bg-blue-500 bg-opacity-30
            ${isExpanded ? 'w-[80%] right-0' : 'w-[39%] right-0'}`}
          style={{ 
            zIndex: isExpanded ? 50 : 1,
            height: isExpanded ? '100%' : '100%',
            overflow: isExpanded ? 'auto' : 'visible'
          }}
        >
          {/* Toggle Button */}
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="absolute -left-4 top-1/2 transform -translate-y-1/2 bg-blue-500 rounded-full p-1 ml-4 text-white hover:bg-blue-600 transition-colors z-50 cursor-pointer shadow-lg"
          >
            {isExpanded ? <ChevronRight size={24} /> : <ChevronLeft size={24} />}
          </button>
  
          <div className={`grid ${isExpanded ? 'grid-cols-2' : 'grid-cols-1'} gap-4`}>
            {/* Original Charts Column */}
            <div>
              <h2 className="text-xl 2xl:text-[1.5rem] 2xl:leading-[2rem] font-bold mb-2">Results</h2>      
  
              {/* Yearly Financial Summary */}
              <div className="h-auto 2xl:h-auto mb-5">
                <div className="bg-blue-500 bg-opacity-20 rounded-lg mb-5">
                  <div className="flex justify-between items-center px-3 py-1">
                    <h3 className="text-lg 2xl:text-[1.5rem] font-semibold">Yearly Financial Summary</h3>
                  </div>
                </div>

                <div className="overflow-x-auto">
                  <table className="min-w-full rounded-lg text-left">
                    <thead>
                      <tr className="bg-blue-300 bg-opacity-30">
                        <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">Year</th>
                        <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">Revenue ($)</th>
                        <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">CapEx ($)</th>
                        <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">OpEx ($)</th>
                        <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">Profit ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...coloTableData, {
                        year: 'Total',
                        Revenue: coloTableData.reduce((sum, row) => sum + convertToInteger(row.Revenue), 0),
                        CapEx: coloTableData.reduce((sum, row) => sum + convertToInteger(row.CapEx), 0),
                        OpEx: coloTableData.reduce((sum, row) => sum + convertToInteger(row.OpEx), 0)
                      }].map((row, index) => {
                        const isTotal = row.year === 'Total';
                        const profit = isTotal 
                          ? row.Revenue - (row.CapEx + row.OpEx)
                          : convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx));
                        
                        return (
                          <tr
                            key={index}
                            className={isTotal 
                              ? "bg-blue-500 bg-opacity-50 font-bold" 
                              : (index % 2 === 0 ? "bg-blue-500 bg-opacity-30" : "bg-blue-400 bg-opacity-30")
                            }
                          >
                            <td className="py-2 px-2 text-xs 2xl:text-[1.5rem]">{row.year}</td>
                            <td className="py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] text-green-500">
                              ${isTotal ? row.Revenue.toLocaleString() : row.Revenue.toLocaleString()}
                            </td>
                            <td className="py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] text-red-500">
                              ${isTotal ? row.CapEx.toLocaleString() : row.CapEx.toLocaleString()}
                            </td>
                            <td className="py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] text-red-500">
                              ${isTotal ? row.OpEx.toLocaleString() : row.OpEx.toLocaleString()}
                            </td>
                            <td className={`py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] ${
                              profit >= 0 ? 'text-green-500' : 'text-red-500'
                            }`}>
                              ${(profit).toLocaleString()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* Profit Calculation */}
                <div className="mt-3 p-1 bg-blue-500 bg-opacity-30 rounded-lg">
                  <h4 className={`text-lg 2xl:text-[1.5rem] font-semibold ${
                    coloTableData.reduce((acc, row) => {
                      return acc + (convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx)));
                    }, 0) >= 0 ? 'text-green-500' : 'text-red-500'
                  }`}>
                    Net Profit = $
                    {coloTableData.reduce((acc, row) => {
                      return acc + (convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx)));
                    }, 0).toLocaleString()}
                  </h4>
                </div>
              </div>

              {/* OpEx Analysis with Revenue Line */}
              <div className="h-48 2xl:h-56 mb-5">
                  <div className="flex flex-row justify-between px-3 bg-blue-500 bg-opacity-20 rounded-lg pt-1 mb-2">
                    <h3 className="text-lg 2xl:text-[1.5rem] 2xl:leading-[2rem] font-semibold">OpEx Vs Revenue Analysis</h3>
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={coloOpExAndRevenueData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="year" padding={{ left: 20, right: 20 }} />
                      <YAxis 
                        domain={getYAxisDomain()}
                        tickCount={8} 
                        tickFormatter={formatYAxisValue}
                        width={100} 
                      />
                      <Tooltip 
                        formatter={(value) => [`$${value.toFixed(2)}`, '']}
                        labelFormatter={(label) => `Year: ${label}`} 
                        contentStyle={{ color: '#000' }} 
                        itemStyle={{ color: '#000' }} 
                      />
                      <Legend />
                      <Line 
                        type="monotone" 
                        dataKey="OpEx" 
                        stroke="#82ca9d" 
                        name="Annual OpEx" 
                        strokeWidth={2} 
                        dot={{ r: 4 }} 
                        activeDot={{ r: 6 }} 
                      />
                      <Line 
                        type="monotone" 
                        dataKey="Revenue" 
                        stroke="#8884d8" 
                        name="Annual Revenue" 
                        strokeWidth={2} 
                        dot={{ r: 4 }} 
                        activeDot={{ r: 6 }} 
                      />
                    </LineChart>
                  </ResponsiveContainer>
              </div>

              {/* Profit Analysis */}
              <div className="hidden 2xl:block">
                <div className="h-48 2xl:h-56 mb-5">
                    <div className="flex flex-row justify-between px-3 bg-blue-500 bg-opacity-20 rounded-lg pt-1 mb-2">
                      <h3 className="text-lg 2xl:text-[1.5rem] 2xl:leading-[2rem] font-semibold">Annual Profit Analysis</h3>
                    </div>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={coloProfit}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="year" padding={{ left: 20, right: 20 }} />
                        <YAxis 
                          domain={getYAxisDomain()}
                          tickCount={8} 
                          tickFormatter={formatYAxisValue}
                          width={100} 
                        />
                        <Tooltip 
                          formatter={(value) => [`$${value.toFixed(2)}`, '']}
                          labelFormatter={(label) => `Year: ${label}`} 
                          contentStyle={{ color: '#000' }} 
                          itemStyle={{ color: '#000' }} 
                        />
                        <Legend />
                        <Line 
                          type="monotone" 
                          dataKey="Profit" 
                          stroke="#ffa500" 
                          name="Annual Profit" 
                          strokeWidth={2} 
                          dot={{ r: 4 }} 
                          activeDot={{ r: 6 }} 
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
              </div>
                  
            </div>
  
            {/* Hidden Charts Column - Only visible when expanded */}
            {isExpanded && (
              <div className="transition-opacity duration-1000 opacity-100">
                <h2 className="text-xl 2xl:text-[1.5rem] 2xl:leading-[2rem] font-bold mb-2">Additional Analysis</h2>
  
                {/* CapEx Breakdown */}
                <div className="h-48 2xl:h-56 mb-5">
                  <div className="bg-blue-500 bg-opacity-20 rounded-lg">
                    <div className="flex flex-row justify-between items-center px-3 py-1">
                      <h3 className="text-lg 2xl:text-[1.5rem]">Total CapEx: ${colo2Costs.colo2TotalCapEx.toLocaleString()}</h3>
                      <h3 className="text-lg font-semibold 2xl:text-[1.5rem]">CapEx Breakdown</h3>
                    </div>
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={coloCapExData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={window.innerWidth >= 2560 ? 30 : 20}
                        outerRadius={window.innerWidth >= 2560 ? 70 : 50}
                        labelLine={true}
                        label={renderCustomizedLabel(coloCapExData)}
                      >
                        {coloCapExData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip formatter={(value) => `$${(value / 1000000).toFixed(1)}M`} />
                      <Legend verticalAlign="middle" align="right" layout="vertical" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                {/* Profit Analysis */}
                <div className="h-48 2xl:h-56 mb-5 2xl:hidden block">
                  <div className="flex flex-row justify-between px-3 bg-blue-500 bg-opacity-20 rounded-lg pt-1 mb-2">
                    <h3 className="text-lg 2xl:text-[1.5rem] 2xl:leading-[2rem] font-semibold">Annual Profit Analysis</h3>
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={coloProfit}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="year" padding={{ left: 20, right: 20 }} />
                      <YAxis 
                        domain={getYAxisDomain()}
                        tickCount={8} 
                        tickFormatter={formatYAxisValue}
                        width={100} 
                      />
                      <Tooltip 
                        formatter={(value) => [`$${value.toFixed(2)}`, '']}
                        labelFormatter={(label) => `Year: ${label}`} 
                        contentStyle={{ color: '#000' }} 
                        itemStyle={{ color: '#000' }} 
                      />
                      <Legend />
                      <Line 
                        type="monotone" 
                        dataKey="Profit" 
                        stroke="#ffa500" 
                        name="Annual Profit" 
                        strokeWidth={2} 
                        dot={{ r: 4 }} 
                        activeDot={{ r: 6 }} 
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              
                {/* Commented out OpEx Table section for now
                <div className="h-auto 2xl:h-auto mt-5">
                  <div className="bg-blue-500 bg-opacity-20 rounded-lg mb-3">
                    <div className="flex justify-between items-center px-3 py-1">
                      <h3 className="text-lg 2xl:text-[1.5rem]">Monthly OpEx: ${colo2Costs?.colo2MonthlyOpEx?.toLocaleString() || 0}</h3>
                      <h3 className="text-lg font-semibold 2xl:text-[1.5rem]">OpEx Breakdown</h3>
                    </div>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="min-w-full rounded-lg text-left">
                      <thead>
                        <tr className="bg-blue-300 bg-opacity-30">
                          <th className="py-2 px-4 text-xs 2xl:text-[1.5rem]">Name</th>
                          <th className="py-2 px-4 text-xs 2xl:text-[1.5rem]">Value ($)</th>
                          <th className="py-2 px-4 text-xs 2xl:text-[1.5rem]">Percentage (%)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coloOpExData?.map((item, index) => {
                          const percentage = ((item.value / colo2Costs?.colo2MonthlyOpEx) * 100).toFixed(2);
                          return (
                            <tr
                              key={index}
                              className={`${
                                index % 2 === 0 ? "bg-blue-500 bg-opacity-30" : "bg-blue-400 bg-opacity-30"
                              }`}
                            >
                              <td className="py-2 px-4 text-xs 2xl:text-[1.5rem]">{item.name}</td>
                              <td className="py-2 px-4 text-xs 2xl:text-[1.5rem]">
                                ${item.value.toLocaleString()}
                              </td>
                              <td className="py-2 px-4 text-xs 2xl:text-[1.5rem]">
                                {percentage}%
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                */}
                
              </div>
            )}
          </div>
        </div>
      </div>
    );
}

export default ColoII;
