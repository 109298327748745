// Chatbot.js
import React, { useState, useRef, useEffect } from 'react';
import { MessageCircle, X, Send } from 'lucide-react';
import { OpenAI } from '@langchain/openai';
import { BufferMemory } from 'langchain/memory';
import { ConversationChain } from 'langchain/chains';

// System prompt for the specialized AI assistant

const SYSTEM_PROMPT = `I am an AI assistant specialized in helping users understand and analyze data center Total Cost of Ownership (TCO) calculations. I can help with:
1. Explaining calculations for:
   - Capital Expenditure (CapEx) including infrastructure, building costs, and initial equipment
   - Operational Expenditure (OpEx) including power, cooling, maintenance, and staffing
   - Revenue projections for both full ownership and colocation models
2. Interpreting dashboard results and metrics:
   - Breaking down cost components
   - Explaining ROI calculations
   - Comparing ownership vs colocation costs
   - Analyzing profit margins and break-even points
3. Understanding key financial metrics:
   - Net Present Value (NPV)
   - Internal Rate of Return (IRR)
   - Payback period
   - Total Cost of Ownership (TCO)
4. Best practices for:
   - Data center capacity planning
   - Cost optimization strategies
   - Infrastructure scaling decisions
   - Maintenance and operational efficiency
5. Detailed component breakdown:
   - Power infrastructure costs
   - Cooling system expenses
   - Network equipment investments
   - Facility maintenance costs
   - Staffing and personnel expenses
I aim to provide clear, accurate explanations and help users make informed decisions about their data center investments.`;

const FAQ_PAIRS = {
  "How is the net profit calculated?": "Net Profit = Total Revenue - (CapEx + OpEx). The calculator considers all revenue streams minus both capital expenses (infrastructure, equipment) and operational expenses (power, cooling, maintenance, staff).",
  "What is included in total CapEx?": "Total CapEx includes:\n- Building/facility costs\n- Power infrastructure\n- Cooling systems\n- Network equipment\n- Security systems\n- Initial software licenses\n- Construction and installation costs",
  "What comprises the monthly OpEx?": "Monthly OpEx consists of:\n- Power consumption costs\n- Cooling system operation\n- Maintenance and repairs\n- Staff salaries\n- Network connectivity\n- Security services\n- Software licenses\n- Insurance",
  "How are colocation costs calculated?": "Colocation costs include:\n- Rack space rental\n- Power usage charges\n- Network bandwidth\n- Remote hands support\n- Setup fees\n- Cross-connect charges\nThese are typically calculated on a per-rack or per-kW basis.",
  "What's the difference between full ownership and colocation?": "Full ownership involves:\n- Higher initial CapEx\n- Complete control over infrastructure\n- Long-term asset ownership\n- Full maintenance responsibility\n\nColocation involves:\n- Lower initial investment\n- Shared facility costs\n- Flexible scaling\n- Reduced maintenance burden",
  "How is the break-even point calculated?": "Break-even point calculation:\n1. Sum all initial CapEx\n2. Add cumulative OpEx over time\n3. Compare against cumulative revenue\n4. Point where revenue equals total costs\nThe dashboard shows this in both timeline and financial metrics views.",
  "How are power costs calculated?": "Power costs calculation:\n1. Total IT load (kW)\n2. PUE (Power Usage Effectiveness)\n3. Hours per month\n4. Cost per kWh\nFormula: IT Load × PUE × Hours × Cost/kWh",
  "What is included in the TCO calculation?": "TCO includes:\n1. All CapEx investments\n2. Cumulative OpEx over facility lifetime\n3. Maintenance and upgrade costs\n4. End-of-life/decommissioning costs\n5. Financing costs and depreciation"
};

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      type: 'bot',
      content: "Hi, I'm your AI assistant! I can help you understand the data center TCO calculator and answer general questions. How can I help you today?"
    }
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const chatModel = useRef(null);
  const memory = useRef(null);
  const chain = useRef(null);

  useEffect(() => {
    const initLangChain = async () => {
      chatModel.current = new OpenAI({
        temperature: 0.7,
        openAIApiKey: process.env.REACT_APP_OPENAI_API_KEY,
      });
      
      memory.current = new BufferMemory();
      
      chain.current = new ConversationChain({
        llm: chatModel.current,
        memory: memory.current,
        prompt: SYSTEM_PROMPT,
      });
    };

    initLangChain();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const checkFAQ = (question) => {
    const normalizedQuestion = question.toLowerCase();
    
    for (const [key, value] of Object.entries(FAQ_PAIRS)) {
      if (key.toLowerCase().includes(normalizedQuestion) || 
          normalizedQuestion.includes(key.toLowerCase())) {
        return value;
      }
    }
    return null;
  };

  const handleSend = async () => {
    if (!inputMessage.trim()) return;

    const userMessage = {
      type: 'user',
      content: inputMessage
    };

    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsTyping(true);

    try {
      const faqAnswer = checkFAQ(inputMessage);
      
      if (faqAnswer) {
        setMessages(prev => [...prev, {
          type: 'bot',
          content: faqAnswer
        }]);
      } else {
        const response = await chain.current.call({
          input: `${inputMessage}\n\nPlease provide a response based on your knowledge of data center TCO calculations and financial metrics.`
        });

        setMessages(prev => [...prev, {
          type: 'bot',
          content: response.response
        }]);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, {
        type: 'bot',
        content: "I apologize, but I encountered an error processing your request. Please try rephrasing your question or ask something else about the TCO calculator."
      }]);
    }

    setIsTyping(false);
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {isOpen && (
        <div className="w-96 h-[500px] bg-white rounded-lg shadow-xl flex flex-col">
          {/* Chat Header */}
          <div className="p-4 bg-blue-500 text-white rounded-t-lg flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <MessageCircle />
              <div>
                <div className="font-semibold">AI Assistant</div>
                <div className="text-xs">Online</div>
              </div>
            </div>
            <button onClick={() => setIsOpen(false)} className="text-white hover:text-white/80">
              <X />
            </button>
          </div>

          {/* Chat Messages */}
          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div
                  className={`max-w-[80%] p-3 rounded-lg ${
                    message.type === 'user'
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                >
                  {message.content}
                </div>
              </div>
            ))}
            {isTyping && (
              <div className="flex justify-start">
                <div className="bg-gray-100 text-gray-800 p-3 rounded-lg">
                  Typing...
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* Chat Input */}
          <div className="p-4 border-t">
            <div className="flex space-x-2">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                placeholder="Type your message..."
                className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={handleSend}
                className="p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                <Send size={20} />
              </button>
            </div>
          </div>
        </div>
      )}

      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          className="w-14 h-14 bg-blue-500 rounded-full flex items-center justify-center shadow-lg hover:bg-blue-600 transition-colors"
        >
          <MessageCircle className="text-white" />
        </button>
      )}
    </div>
  );
};

export default Chatbot;